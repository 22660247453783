import { defineStore } from 'pinia'
import { DATA_ENTRY_URL } from '../../../config.js';


//endpoint: /labs => lista dei laboratori

//la GET ritorna un array, ogni elemento è fatto così:

//{
//    "id": 1,
//    "name": "ARPA"
//  },



export const useLabsStore = defineStore({
    id: 'labs',
    state: () => ({
        labs: [],
        isLoading: false,
        error: null,

      }),
      getters: {
        countLabs(){
            return this.labs.length;
        },
      }, 
      actions: {
        async getLabsList(){
          this.loading = true;
          try{
              const res = await fetch(DATA_ENTRY_URL + '/labs');
      
              const data = await res.json();
              if(res['status'] != 200 || data['detail']){
                throw new Error('Errore nella richiesta');
              }
              this.labs = data;
              }catch(e){
                  this.error = e;
                  throw(e);                 
              }finally{
                  
              this.loading = false;
              }
        },
        getLabByName(lab_name){
          let ciao;
          this.labs.find(item => {
            if(item.name === lab_name){
              ciao = item.id;
            }
          });
          return ciao;
        },
        resetInstance(){
          this.labs=[];
          this.loading= false;
          this.error= null;
        }
      },
});