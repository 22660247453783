import { defineStore } from 'pinia'
import nodesData from '../data/nodes.json'
export const useNodeStore = defineStore({
  id: 'node',
  state: () => ({
    nodes: [],
    node: {},
    stazione_corrente: null,
    loading: false,
    error: null
  }),
  getters: {
    countNodes() {
      return this.nodes.length;
    },
    getStazioni() {

      //I nodi con fixedPoint == 1 rappresentano le stazioni
      let ret = this.nodes.filter((node) => node.fixedPoint == 1);

      //Ordinamento delle stazioni in base al nome. Sul json (src/data/nodes.json), è impossibile ordinarle per id.
      ret.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();

        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });

      return ret;
    },
    getCurrentNode() {
      return this.node;
    },
  },
  actions: {

    initNodes() {
      this.nodes = JSON.parse(JSON.stringify(nodesData.data));
      this.node = {};
    },

    resetNode() {
      this.node = {};
    },
    getNodeById(node_id) {

      let ret = this.nodes.find((node) => node.node_id == node_id);
      return ret;

    },
    getNodeByCoordinates(lat, lng) {
      //STEP 2 => Per ogni nodo controllo se combaciano in quelle cire
      let ret = this.nodes.find(node =>
        lat === node.lat && lng === node.lon
      );
      return ret;
    },

    updateCurrentNode(node_id) {

      let curNode = this.nodes.find((node) => node.node_id == node_id);

      if (curNode != null) {
        this.node = curNode;
        return true;
      } else {
        return null;
      }

    }, addNodeAnalisis(analisi_nodo, analisi_date) {

      if (!this.node) return;

      this.node['exams'] = analisi_nodo;
      this.node['exam_length'] = analisi_nodo.length;
      this.node['date'] = analisi_date;

      //Aggiungo la proprietà "analyzed" per indicare che il nodo contiene un'analisi.
      this.node['analyzed'] = true;
    },
    getRiskColor(risk_index) {    //assegno colore in base al valore di rischio
      const coloreBase = [255, 1, 1]; //rosso acceso !

      const step = 25.5; //255 / 10

      let grav = risk_index * step; //cambio solo il channel verde (così il colore va da rosso acceso a giallo acceso)

      let channelVerde = 255 - grav;

      coloreBase[1] = channelVerde.toFixed(2);
      return `rgb(${coloreBase})`;
    },
    addNodeRisk(risk_index) {
      this.node['risk_index'] = risk_index;
      this.node['risk_color'] = this.getRiskColor(risk_index);
    },
    addRiskProperty() {
      this.node['analyzed_risk'] = true;
    },
    getStazioniArrayPosition(node_ID) { //restituisce la posizione nell'array di una stazione
      let stazioni = this.getStazioni;
      //ottengo la posizione della stazione con ID node_ID

      let indexStazione;
      for (let i = 0; i < stazioni.length; i++) {
        if (stazioni[i].node_id === node_ID) {
          indexStazione = i;
          break;
        }
      }
      return indexStazione; //può essere null
    },
    getCoordinateNodoById(node_id) {
      let currNode = this.getNodeById(node_id);
      let coordinateNodo = [currNode['lat'], currNode['lon']];
      return coordinateNodo;
    }

  }
})