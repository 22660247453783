import { defineStore } from 'pinia'
import {API_BASE_URL } from '../../config.js';
import polyline from '@mapbox/polyline';

export const useOptRouteStore = defineStore({
    id: 'optimization_route',
    state: () => ({
        routes: [],
        squadre_id: null,
        selected_route_info: null, //tiene info sulla route della squadra, da mostrare nel testo in alto (distanza_percorsa, tempo)
        loading: false,
        error: null,
        legendRoute: null, //info della route => da mostrare nella legenda on-hover sulla mappa
    }),
    getters: {
        countRoutes() {
            return this.routes.length;
        },
        //ritorno le route di quella squadra !
        getSquadraRoutes(){
            if(this.selected_route_info === null) return;
            let squadra_routes = [];
            this.routes.forEach((routeData) => {
                if(this.selected_route_info['team_index'] === routeData['team_index']){
                    squadra_routes.push(routeData);
                }
            });
            return squadra_routes;
        }
    },
    actions: {
        //prendo tutte le route per quest'ottimizzazione
        async getRoutesById(optimization_id) {

            this.loading = true;

            try {
                const res = await fetch(`${API_BASE_URL}/api/route/${optimization_id}`);
                
                const data = await res.json();

                this.routes = data;

                //devo decodificare
                if(this.routes.length < 1){
                    throw("Non ci sono risultati");
                }
                let squadreId = new Set();
                //ho i dati => decodifico i polyline per ogni route
                for(let i = 0; i< this.countRoutes; i++){
                    let polyline_decoded = this.decodePolylineToCoordinates(this.routes[i]['encodedPolyline']);
                    this.routes[i]['decodedPolyLine'] = polyline_decoded;
                    //non mi serve più
                    this.routes[i]['encodedPolyline'] = null;
                    squadreId.add(this.routes[i]['team_index']);
                }
                //converto Set in array. In questo modo mi assicuro che l'array non ha elementi doppi
                this.squadre_id = [...squadreId];
            } catch (e) {
                this.error = e;
                throw(this.error);
            } finally {
                this.loading = false;
            }
        },
        async getRouteInfoByIds(optimization_id, squadra_id) {
            this.loading = true;
            try {
                const res = await fetch(`${API_BASE_URL}/api/route/${optimization_id}/${squadra_id}`);
                
                const data = await res.json();

                //devo decodificare
                if(data['detail']){
                    throw("Non ci sono risultati");
                }

                this.selected_route_info = data;
            } catch (e) {
                this.error = e;
                throw(this.error);
            } finally {
                this.loading = false;
            }
        },
        decodePolylineToCoordinates(encoded_polyline){
            //uso mapbox per decodificare ste cose
            return polyline.decode(encoded_polyline);
        },
        //quando utente va on-hover su una route della mappa => mostro le info di quella route
        toggleRouteMapLegend(route_to_display){
            this.legendRoute = route_to_display;
        }
    }
})