<template>
  <div class="flex items-center justify-center h-screen bg-gray-100">
    <div class="bg-white p-8 shadow-md rounded-lg">
      <h1 class="text-2xl font-semibold mb-4">Login Utente</h1>
      <form @submit.prevent="userLogin" class="space-y-4">
        <div>
          <label for="username" class="block font-medium">Username:</label>
          <input type="text" id="username" v-model="formData.username" required
            class="w-full border-gray-300 rounded-md p-2">
        </div>
        <div>
          <label for="password" class="block font-medium">Password:</label>
          <input type="password" id="password" v-model="formData.password" required
            class="w-full border-gray-300 rounded-md p-2">
        </div>
        <div>
          <button type="submit" class="w-full text-white rounded-md py-2 hover:bg-blue-600">
            Accedi
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useAuthStore } from "../stores/auth";
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';


const router = useRouter();

const authStore = useAuthStore();
const formData = ref({
  username: "",
  password: "",
});

function userLogin() {
  const { username, password } = formData.value;


  authStore.login(username, password).then((res) => {
    if (res) {
      router.push({ name: 'home' });
    } else {
      Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Autenticazione fallita !",
                });
    }

  })

}
</script>