import { defineStore } from 'pinia'

import { API_BASE_URL } from '../../config.js';
import Cookies from 'js-cookie'

export const useAuthStore = defineStore({

  id: 'auth',

  state: () => ({

    access_token: null,
    refresh_token: null,
    isLoading: false,
    error: null,
    username: null,

  }),

  getters: {

    getAccessToken() {

      return this.access_token;

    },

    getRefreshToken() {

      return this.refresh_token;

    },
    isAuthenticated(){
      if(this.access_token){
        return true;
      }

      return false;
    }
  },

  actions: {

    setAccessToken(access_token) {

      this.access_token = access_token;

    },

    setRefreshAccessToken(refresh_token) {

      this.refresh_token = refresh_token;

    },
    resetTokens(){
      this.access_token = null;
      this.refresh_token = null;
    },

    //C0OKIE FUNCTIONS 
    setCookie(){
        Cookies.set('user_is_auth', this.access_token,{ SameSite: 'None',secure: true });
        Cookies.set('username', this.username, { SameSite: 'None',secure: true });
    },
    removeCookie(){
      Cookies.remove('user_is_auth');
      Cookies.remove('username');
    },
    getCookieContent(){
      let cookie = Cookies.get('user_is_auth');
      let user = Cookies.get('username');
      if(cookie){
        this.access_token = cookie;
        this.username = user;
      }
      return cookie;
    },

    //OTHER FUNC

    async login(user, psw) {
      this.isLoading = true;
      let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
      const body = { "username": user, "password": psw };

      try {
        let data = await fetch(`${API_BASE_URL}/login`, {
          headers: headers,
          body: JSON.stringify(body),
          method: "POST"

        });

        let response = await data.json();
        if(response['detail']){
          return false;
        }
        this.access_token = response['access_token'];
        this.refresh_token = response['refresh_token'];
        this.username = user;
        this.setCookie();
        return true;
      } catch (e) {
        this.error = e;
        this.resetTokens();
        this.username = null;
        this.removeCookie();
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    logout(){
      this.resetTokens();
      this.removeCookie();
      this.username = null;
    }

  },
});
