<template>
    <div class="flex flex-col xl:flex-row bg-blue-200">
        <div class="w-full p-4">
            <div class="flex justify-end mb-2">
                <!-- BOTTONE PER APRIRE IL MODAL -->
                <button @click="openModal()"
                    class="px-4 py-2 bg-blue-500 text-white rounded-lg transition hover:bg-blue-600"><i class="fa fa-square-plus" style="padding:5px;margin-right:10px;"></i>Aggiungi
                    Campione
                </button>
            </div>

            <!-- MODAL PER IL FORM -->
            <div v-if="displayModal" class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                <div class="bg-white p-6 rounded-lg">
                    <h2 class="text-lg font-semibold mb-4"> {{ sampleIDToEdit == null ? 'Aggiungi ' : 'Modifica ' }} Campione</h2>

                    <!-- FORM PER INSERIRE UN NUOVO SAMPLE -->

                    <form @submit.prevent="handleSubmit" class="flex flex-col">
                       <div class="mb-4 flex items-center">
                            <label for="data" class="text-sm font-medium text-gray-700 w-24">Data:</label>
                            <input type="date" id="data" v-model="data" required
                                class="p-2 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300">
                        </div>

                        <div class="mb-4 flex items-center">
                            <label for="ora" class="text-sm font-medium text-gray-700 w-24">Ora:</label>
                            <input type="time" id="ora" v-model="ora" required
                                class="p-2 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300">
                        </div>

                        <div class="mb-4 flex items-center">
                            <label for="tipo" class="text-sm font-medium text-gray-700 w-24">Tipo:</label>
                            <select id="tipo" v-model="type" @change="handleTypeChange"
                                class="p-2 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300 "
                                >
                                <option value="Fixed">Fisso</option>
                                <option value="Mobile">Mobile</option>
                            </select>
                            
                        </div>

                                            
                        <div class="mb-4 flex items-center">
                            <label for="point" class="text-sm font-medium text-gray-700 w-24">Net Point:</label>
                            <Dropdown class="p-0 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300" v-model="point" :options="points" optionLabel="pointID" :filter="true" placeholder="Seleziona il Tipo"/>
                        </div>

                        <div class="mb-4 flex items-center">
                            <label for="lab" class="text-sm font-medium text-gray-700 w-24">Laboratorio:</label>
                            <select id="lab" v-model="lab" required
                                class="p-2 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300">
                                <option v-if="!labsCallSuccess" disabled> Laboratori non disponibili </option>
                                <option v-else v-for="laboratorio in labsStore.labs" :key="laboratorio.id" :value="laboratorio.id">{{ laboratorio.name }}
                                </option>
                            </select>
                        </div>


                        <div class="flex justify-end space-x-4">
                            <button type="button"
                                class="px-2 py-1 bg-red-500 text-white rounded-lg transition hover:bg-red-600 text-sm w-16"
                                @click="closeModal">Annulla</button>
                            <button type="submit"
                                class="px-2 py-1 bg-green-500 text-white rounded-lg transition hover:bg-blue-600 text-sm w-16">Salva</button>
                        </div>
                    </form>

                </div>
            </div>

            <!--Pulsanti di navigazione della tabella-->
            <div class="flex justify-start mb-3 space-x-4 h-[15px]">
              <button @click="prevPage" :disabled="currentPage === 1" :class="currentPage === 1 ? 'text-grey-500' : 'text-blue-500'">
                <i class="fas fa-chevron-left"></i> Precedente
              </button>
              <button @click="nextPage" :disabled="currentPage * limit >= sampleListStore.countSamples"
                :class="currentPage * limit < sampleListStore.countSamples ? 'text-blue-500' : 'text-grey-500'">
                Successivo <i class="fas fa-chevron-right"></i>
              </button>
            </div>
            <div class="overflow-x-auto bg-gray-100 rounded-lg shadow-md flex-grow" v-if="sampleCallSuccess">
                <table class="w-full bg-white border rounded-lg shadow-md overflow-y-auto">
                    <thead>
                        <tr class="bg-gray-100 border-b">
                           <!-- <th class="p-4 text-left">ID</th> -->
                            <th class="p-4 text-left">Data <i :class="'fas fa-caret-' + (dataFilter ? 'down' : 'up')" @click="toggleDateFilter" style="cursor: pointer;" title="Ordina per data"></i></th>
                            <th class="p-4 text-left">Tipo <i :class="'fas fa-caret-' + (typeFilter ? 'down' : 'up')" @click="toggleTypeFilter" style="cursor: pointer;" title="Ordina per tipo"></i></th>
                           <!-- <th class="p-4 text-left">Stato</th> -->
                            <th class="p-4 text-left">Point ID <i :class="'fas fa-caret-' + (pointFilter ? 'up' : 'right')" @click="togglePointFilter" style="cursor: pointer;" title="Filtra per punto"></i>
                                <br>
                                <input v-if="pointFilter" type="text" v-model="pointIdFilter" class="p-1 border rounded-md w-24 focus:outline-none focus:ring focus:border-blue-300">

                            </th>
                            <th class="p-4 text-left">Sample ID <i :class="'fas fa-caret-' + (sampleFilter ? 'up' : 'right')" @click="toggleSampleFilter" style="cursor: pointer;" title="Filtra per campione"></i>
                                <br>
                                <input v-if="sampleFilter" type="text" v-model="sampleIdFilter" class="p-1 border rounded-md w-24 focus:outline-none focus:ring focus:border-blue-300">
                            </th>
                            <th class="p-4 text-left">Ruolo <i :class="'fas fa-caret-' + (roleFilter ? 'down' : 'up')" @click="toggleRoleFilter" style="cursor: pointer;" title="Ordina per ruolo"></i></th>
                            <th class="p-4 text-center">Azioni</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b" v-for="sample in displayedSamples" :key="sample.id">
                            <td class="p-4"> {{ sample.sampleDate }} <br>{{ sample.sampleTime }} </td>
                            <td class="p-4"> {{ sample.sampleType }} </td>
                            <td class="p-4"> {{ sample.pointID }} </td>
                            <td class="p-4"> {{ sample.sampleID }} </td>
                            <td class="p-4"> {{ sample.role }} </td>
                            <td class="p-4" style="justify-content: space-evenly; display: flex;">
                                <i class="fa-solid fa-edit" title="modifica sample" style="cursor: pointer; color: green;" @click="openModal(sample)"></i> 
                                <i class="fa-solid fa-trash" title="Elimina Sample" style="cursor: pointer; color: red" @click="openDeleteModal(sample)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <p v-else style="text-align: center;"> Campioni non disponibili </p>
        </div>
    </div>
</template>
  
  
<script setup>
import { ref, watch, computed } from 'vue';
import { useSamplesListStore } from "../stores/data_entry/samples_list";
import { useNetpointsStore } from "../stores/data_entry/netpoints";
import { useLabsStore } from "../stores/data_entry/labs";
import { useAuthStore } from "../stores/auth";
import Swal from 'sweetalert2';
import Dropdown from 'primevue/dropdown';

const data = ref(getCurrentDate());
const ora = ref(getCurrentTime());
const type = ref(null);
const point = ref('');
const lab = ref('');
const displayModal = ref(false);

const currentPage = ref(0);
const limit = ref(8);



//chiamata api ai samples
const sampleCallSuccess = ref(false);
//chiamata api ai netpoint
const netpointsCallSuccess = ref(false);

//chiamata api ai laboratori
const labsCallSuccess = ref(false);
const sampleListStore = useSamplesListStore();
const netpointsStore = useNetpointsStore();
const labsStore = useLabsStore();

const points = ref(null);

//filtro per data
const dataFilter = ref(false);
//filtro per tipo
const typeFilter = ref(false);

//filtro per ruolo
const roleFilter = ref(false);

const pointFilter = ref(false);
const pointIdFilter = ref('');

const sampleFilter = ref(false);
const sampleIdFilter = ref('');

//variabile che mi fa capire se sto modificando un sample o creandone uno nuovo !
let sampleIDToEdit;

//variabile in cui inserisco il sample da eliminare
let sampleIDToDelete;


//variabile che contiene i dati del sample. se non è null => utente vuole modificarlo. se è null => utente vuole creane uno nuovo
const openModal = (sampleToEdit) => {
    //utente è in modifica
    if(sampleToEdit){
        sampleIDToEdit = sampleToEdit.id;
        type.value = sampleToEdit.sampleType;
        
        //aggiorno i netpoints in base al tipo
        handleTypeChange(sampleToEdit);

        data.value = sampleToEdit.sampleDate;
        ora.value = sampleToEdit.sampleTime;

        //assegno l'id del laboratorio. sampleToEdit.role è il nome
        lab.value = labsStore.getLabByName(sampleToEdit.role);
    }else{
        //resetto la data
        data.value = getCurrentDate();
        ora.value = getCurrentTime();
    }
    displayModal.value = true;
};

const closeModal = () => {
    displayModal.value = false;
    clearRefs();
};

const closeDeleteModal = () => {
    sampleIDToDelete = null;
};

const openDeleteModal = (sample) =>{
    sampleIDToDelete = sample.id;

    Swal.fire({
        title: "Sei sicuro di voler eliminare questo campione ?",
        text: "L'operazione è irreversibile !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Elimina campione!",
    }).then((result) => {
        if (result.isConfirmed) { //ha premuto il tasto elimina
            deleteSample();
        }
});
};

//ottengo i campioni
sampleListStore.getSamplesList()
    .then(() => {
        sampleCallSuccess.value = true;
        currentPage.value = 1;
    })
    .catch(() => {
        sampleCallSuccess.value = false;
        currentPage.value = 0;
    });


labsStore.getLabsList()
    .then(() => {
        labsCallSuccess.value = true;
    })
    .catch(() => {
        labsCallSuccess.value = false;
    });



//premo il pulsante per salvare il sample
//campi obbligatori: id_laboratorio e id_punto
const handleSubmit = () => {

    if(!point.value || point.value == ''){
        Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Non hai selezionato alcun punto !",
            });
        return;
    }

    const authStore = useAuthStore();

    //se sto Modificando, questa variabile ha l'id del sample da modificare. se è null => allora ne creo uno nuovo
    if(sampleIDToEdit){
        sampleListStore.updateSample(sampleIDToEdit, authStore.access_token, data.value, ora.value, point.value.id, type.value, lab.value)
            .then(() => {
                Swal.fire({
              icon: "success",
              text: "Campione modificato con successo !",
            })            
            .then(() => {
                window.location.reload();
                return;
            });
            })
            .catch(() => {
                Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Campione non modificato !",
            });
            });
    }else{

        sampleListStore.createSample(authStore.access_token, data.value, ora.value, point.value.id, type.value, lab.value)
            .then(() => {
            Swal.fire({
                icon: "success",
                text: "Campione creato con successo !",
            })
            .then(() => {
                    window.location.reload();
                    return;
            });
            })
            .catch(() => {
                Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Campione non creato !",
            });
            });
    }

    //chiudo il Modal !
    closeModal();
    
};



// Quando seleziono se il tipo di nodo è fisso o mobile => chiamo endpoint per ottenere la lista dei punti
const handleTypeChange = (sampleToUpdate) => {
    let param = type.value === "Fixed" ? 1 : type.value === "Mobile" ? 0 : null;
    netpointsStore.getNetpointsList(param)
        .then(() => {
            netpointsCallSuccess.value = true;
            points.value = netpointsStore.netpoints;
            //allora l'utente sta modificando un sample, e devo assegnare il valore al dropdown
            if(sampleToUpdate){
                point.value = netpointsStore.getNetpointByName(sampleToUpdate.pointID);
            }
        })
        .catch(() => {
            netpointsCallSuccess.value = false;
            points.value = null;
        });
};


//ogni volta che cambio il tipo del nodo dal dropdown => il dropdown con i punti si azzera
watch(
    () => type.value,
    () => {
        point.value = '';
    }
);

const deleteSample = () => {
    const authStore = useAuthStore();
    sampleListStore.deleteSample(sampleIDToDelete, authStore.access_token)
    .then(() => {
        Swal.fire({
              icon: "success",
              text: "Campione eliminato con successo !",
            })
            .then(() => {
                closeDeleteModal();
                window.location.reload();
            });
        })
        .catch(() => {
            closeDeleteModal();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Qualcosa è andato storto ",
            });
        });
};


//FILTRI
const toggleDateFilter = () => {
    sampleListStore.samples.reverse();
    dataFilter.value = !dataFilter.value;
};

const toggleTypeFilter = () => {
    typeFilter.value = !typeFilter.value;
    sampleListStore.sortElementsByType(typeFilter.value);
};

const toggleRoleFilter = () => {
    roleFilter.value = !roleFilter.value;
    sampleListStore.sortElementsByRole(roleFilter.value);
};

const toggleSampleFilter = () => {
    pointFilter.value = false;
    pointIdFilter.value = '';
    sampleFilter.value = !sampleFilter.value;
    sampleIdFilter.value = '';
};

const togglePointFilter = () => {
    sampleFilter.value = false;
    sampleIdFilter.value = '';
    pointFilter.value = !pointFilter.value;
    pointIdFilter.value = '';
};


//FUNZIONI PER NAVIGAZIONE TABELLA

const displayedSamples = computed(() => {
  const startIndex = (currentPage.value - 1) * limit.value;
  const endIndex = startIndex + limit.value;

  if(sampleFilter.value){
    const filteredSamples = sampleListStore.samples.filter(sample => {
        return sample.sampleID.toLowerCase().includes(sampleIdFilter.value.toLowerCase());
    });
    return filteredSamples.slice(startIndex, endIndex);
  }

  if(pointFilter.value){
    const filteredSamples = sampleListStore.samples.filter(sample => {
        return sample.pointID.toLowerCase().includes(pointIdFilter.value.toLowerCase());
    });
    return filteredSamples.slice(startIndex, endIndex);
  }

  return sampleListStore.samples.slice(startIndex, endIndex);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value * limit.value < sampleListStore.countSamples) {
    currentPage.value++;
  }
};


//FUNZIONI

function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function getCurrentTime() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}


//resetto tutti i ref.
function clearRefs(){
    sampleIDToEdit = null;
    data.value = '';
    ora.value = '';
    type.value = null;
    point.value = '';
    lab.value = '';
    points.value = null;
}

</script>


<style scoped>
@import '../assets/styles/optimizationHistory.css';

.p-dropdown .p-dropdown-trigger{
    color: red;
}
</style>