<template>
  <div class="h-full">
    <!--Pulsanti di navigazione della tabella-->
    <div class="flex justify-end mb-3 space-x-4 h-[15px]">
      <button @click="prevPage" :disabled="currentPage === 1" class="text-blue-500" v-if="currentPage > 1">
        <i class="fas fa-chevron-left"></i> Precedente
      </button>
      <button @click="nextPage" :disabled="currentPage * limit >= optimizationStore.optimization.length"
        class="text-blue-500" v-if="currentPage * limit < optimizationStore.optimization.length">
        Successivo <i class="fas fa-chevron-right"></i>
      </button>
    </div>

    <div class="overflow-x-auto bg-gray-100 rounded-lg shadow-md" v-if="ottimizzazioni.length > 0">

      <table class="min-w-full bg-white border rounded-lg shadow-md overflow-y-auto">
        <thead>
          <tr class="bg-gray-100 border-b">
            <!-- <th class="p-4 text-left">Id Ottimizzazione</th> -->
            <th class="p-4 text-left">Data Ottimizzazione</th>
            <th class="p-4 text-left">Numero Squadre</th>
            <th class="p-4 text-left">Numero Campionati</th>
            <th class="p-4 text-left">Gamma</th>
            <th class="p-4 text-left">Dettaglio</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in displayedOptimizations" :key="item.id" class="border-b">
            <!-- <td class="p-4">{{ item.id }}</td> -->
            <td class="p-4 w-[200px]">{{ formatDateTime(item.time) }}</td>
            <td class="p-4">{{ item.n_teams }}</td>
            <td class="p-4">{{ item.n_samples }}</td>
            <td class="p-4">{{ item.gamma }}</td>
            <td class="p-4 flex items-center justify-center">
              <button @click="showModal(item.id)">
                <i class="fas fa-search"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="flex justify-center items-center h-[500px]">
      <p class="text-gray-500">Nessuna ottimizzazione disponibile per questa data.</p>
    </div>

    <div v-if="isModalVisible" class="overlay">
      <div class="modal-opt" style="display: block">
        <div class="modal-opt-content">
          <h3 class="modal-opt-title">Risultato Ottimizzazione</h3>
          <button @click="closeModal" class="modal-opt-close-btn">×</button>
          <div class="relative flex items-end space-x-2">
            <label class="block font-bold mb-1 justify-start" for="date-range">Seleziona una squadra:<br></label>
            <select v-model="squadraSelezionata" @change="handleNewSquadra"
              class="w-2/4 px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300 shadow-md">
              <option v-if="optRouteStore.squadre_id === null" value="" disabled selected><i>Selezionare una
                  Squadra</i></option>
              <option v-for="squadra in optRouteStore.squadre_id" :key="squadra" :value="squadra">squadra {{ squadra + 1
              }}
              </option>
            </select>
          </div>
          <div v-if="optRouteStore.selected_route_info !== null" class="m-2 mt-5 flex">
            <div class="mr-2">
              <p><strong>Distanza:</strong></p>
            </div>
            <div>
              <p>{{ optRouteStore.selected_route_info['distanceKm'] }} Km</p>
            </div>
            <div class="mr-2 ml-4">
              <p><strong>Durata:</strong></p>
            </div>
            <div>
              <p>{{ durataFormat(optRouteStore.selected_route_info['durationSeconds']) }}</p>
            </div>
          </div>

          <div style="display: grid; grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); grid-gap: 10px;">
            <div v-for="route in optRouteStore.getSquadraRoutes" :key="route" :value="route">
              <div class="legend-item" @mouseover="optRouteStore.toggleRouteMapLegend(route)"
                @mouseleave="optRouteStore.toggleRouteMapLegend(null)">
                <div class="legend-color" :style="{ 'background-color': route.color }"></div>
                <p>route {{ route.section_number + 1 }}</p>
              </div>
            </div>
          </div>
          <MapComponent class="h-[420px] mt-5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import MapComponent from './MapComponent.vue'
import { useOptimizationStore } from "../stores/optimization";
import { useOptRouteStore } from '../stores/optimization_route';

const optimizationStore = useOptimizationStore();
const optRouteStore = useOptRouteStore();
const isModalVisible = ref(false);
const currentPage = ref(1);
const limit = ref(7);
let ottimizzazioni = ref([]);
let squadraSelezionata = ref(null);
let current_ottimizzazione_id = null;

watch(ottimizzazioni, () => {
  currentPage.value = 1;
});

watch(
  () => optimizationStore.optimization,
  () => {
    ottimizzazioni.value = optimizationStore.optimization;
  });
const showModal = (ottimizzazione_id) => {
  current_ottimizzazione_id = ottimizzazione_id;
  isModalVisible.value = true;
  optRouteStore.getRoutesById(ottimizzazione_id)
    .then(() => {
      squadraSelezionata.value = optRouteStore.squadre_id[0];
      //chiamata per quella squadra !
      optRouteStore.getRouteInfoByIds(ottimizzazione_id, optRouteStore.squadre_id[0]);
    })
    .catch(() => {
      isModalVisible.value = false;
      current_ottimizzazione_id = null;
    });
};

const displayedOptimizations = computed(() => {
  const startIndex = (currentPage.value - 1) * limit.value;
  const endIndex = startIndex + limit.value;
  return optimizationStore.optimization.slice(startIndex, endIndex);
});

const closeModal = () => {
  isModalVisible.value = false;
  current_ottimizzazione_id = null;
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value * limit.value < optimizationStore.optimization.length) {
    currentPage.value++;
  }
};

const formatDateTime = (dateTimeString) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  return new Date(dateTimeString).toLocaleDateString(undefined, options);
};

function durataFormat(durationSeconds) {
  const hours = Math.floor(durationSeconds / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const seconds = durationSeconds % 60;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}


const handleNewSquadra = async () => {
  if (squadraSelezionata.value !== null) {
    //ho l'id della squadra
    optRouteStore.getRouteInfoByIds(current_ottimizzazione_id, squadraSelezionata.value)
      .catch(() => {
        closeModal();
      });
  }
};
</script>

<style scoped>
@import '../assets/styles/optimizationHistory.css';
</style>

