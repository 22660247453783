import { defineStore } from 'pinia'
import { API_BASE_URL } from '../../config.js';




export const useGraficoAnalisiStore = defineStore({
  id: 'grafico_analisi',
  state: () => ({
    analisi:[],
    nodesNotFound: [],
    loading: false,
    error: null
  }),
  getters: {
    getAnalisi(){
      return this.analisi;
    }
  }, 
  actions: {
    resetInstance(){
      this.analisi = [];
      this.nodesNotFound = [];
      this.loading = false;
      this.error = null;
    },
    async getAnalisiById(analisi_id){
      this.loading = true;
      try{
          const res = await fetch(API_BASE_URL + '/api/exams/' + analisi_id);
  
          const data = await res.json();
  
          if(res['status'] != 200 || data['detail']){
              throw("errore");
          }
          this.analisi = data;
          }catch(e){
              this.error = e;
              alert(this.error);
          }finally{
          this.loading = false;
          }
    },
    filterAnalisiByNodes(nodes_id){
      this.nodesNotFound = [];
      //Mi tengo solo le analisi per determinati Nodi !
      //nodes_id è un array che contiene gli ID dei nodi selezionati dall'utente

      //2 filtri:
      //1) da nodes_id tolgo quei nodi che non hanno analisi. 
            //Se un nodo non ha analisi verrà visualizzato un testo sotto al grafico con scritto: il nodo con ID non ha analisi
      var filter_1 = nodes_id.filter(id => {
        if(this.analisi.some(exam => exam.nodeID === id)){
          return true;
        }else{
          this.nodesNotFound.push(id);
        }
      });

      //2) mi prendo le analisi dei nodi che hanno ID selezionato dall'utente
      var filter_2 = this.analisi.filter(exam =>{
        if(filter_1.includes(exam.nodeID)){
          exam.values.reverse();
          return true;
        }
        return false;
      });


      
      this.analisi = filter_2;

    },
  },
})