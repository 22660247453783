<template>
  <div>
    <div class="main-wrapper mt-6" :class="{ loading: isLoading }">
      <div class="max-w-md p-4 bg-gray-100 border border-gray-200 rounded-lg shadow-md h-[455px]">
        <h4 class="text-lg font-semibold mb-4">Configura Ottimizzazione</h4>

        <form @submit.prevent="ottimizza" class="flex flex-col justify-between rounded-md p-4 space-y-2">
          <div class="">
            <label for="numSquadre" class="font-semibold">N. squadre:</label>
            <input type="number" id="numSquadre" v-model="numSquadre" required class="input-field mx-2 rounded-md" />
          </div>
          <br>
          <div class="">
            <label for="numCampionamentiPerSquadra" class="font-semibold">N. campionamenti:</label>
            <input type="number" id="numCampionamentiPerSquadra" v-model="numCampionamentiPerSquadra" required
              class="input-field mx-2 rounded-md" />
          </div>
          <br>
          <div class="">
            <label for="parametro" class="font-semibold">Gamma:</label>
            <div class="question-icon blue-icon" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                <i class="fas fa-question-circle"></i>
                <div v-if="showTooltip" class="tooltip">
                  <p>Il parametro <strong>gamma</strong> controlla il peso del termine logistico nel problema di ottimizzazione dei punti di campionamento. 
                    <br>
                    Impostare un valore di gamma elevato se si vuole ridurre la distanza percorsa dalle squadre.
                  </p>
                </div>
              </div>
              <br>
              <input type="range" id="parametro" v-model="parametro" min="0" max="1" step="0.01"
                class="input-range mx-2" />
              <span class="font-bold text-blue-500">{{ parametro }}</span>
          </div>
          <br>
            <div class="flex justify-end">
              <button type="submit"
                class="px-4 py-2 bg-blue-500 text-white rounded-lg transition hover:bg-blue-600 mb-2">Avvia</button>
            </div>
            <p class="mt-5"><i><small>(Tempo massimo stimato per l'ottimizzazione: 2 minuti)</small></i></p>

        </form>
      </div>

      <div v-if="isLoading || successOptimization" class="overlay flex">
        <div class="loader-container flex flex-col items-center justify-center h-[300px]" id="modal_optimization">
          <img v-if="isLoading" src="@/assets/loader.gif" alt="Loader" class="w-32 h-32" />
          <i v-else class="fas fa-check-circle text-green-500 text-5xl"></i>
          <p class="mt-2" v-if="isLoading">L'ottimizzazione è stata lanciata ed il tempo stimato è al massimo di 2 minuti.
            Grazie per l'attesa.</p>
          <p class="mt-2" v-else>Ottimizzazione lanciata con successo! I risultati saranno visibili nella tabella dello
            storico.</p>
          <button v-if="!isLoading" @click="closeModal"
            class="mt-5 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Ok</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useOptimizationStore } from "../stores/optimization";
import { useCampagneStore } from "../stores/campagne";
import { useAuthStore } from "../stores/auth";

const showTooltip = ref(false);
const numSquadre = ref(1);
const numCampionamentiPerSquadra = ref(3);
const parametro = ref(0.5);
const isLoading = ref(false);
const successOptimization = ref(false);
const authStore = useAuthStore();


const optimizationStore = useOptimizationStore();
const campagneStore = useCampagneStore();

watch(
  () => optimizationStore.isLoading || optimizationStore.successOptimization,
  () => {
    isLoading.value = optimizationStore.isLoading;
  });

const ottimizza = () => {
  optimizationStore.runOptimization(
    campagneStore.camp_selezionata_id,
    numSquadre.value,
    numCampionamentiPerSquadra.value,
    parametro.value,
    authStore.access_token
  ).then(() => {
    successOptimization.value = true;
  });
};

function closeModal() {
  successOptimization.value = false;
  isLoading.value = false;
}



</script>

<style scoped>
@import '../assets/styles/optimizationForm.css';

</style>
