<template>
  <div class="legend-modal" :class="{ active: showModal }">
    <div class="legend-content">
      <div class="map-index">

        <!--Legenda Mappa Rischio-->
        <div v-if="router.currentRoute.value.name === 'risk'">
          <div class="legend-item">
            <div class="legend-color red"></div>
            <p>Critical points</p>
          </div>
          <div class="legend-item">
            <div class="legend-color yellow"></div>
            Non-critical points
          </div>
          <div class="legend-item">
            <div class="legend-arrow">
              <svg width="12" height="12" viewBox="0 0 12 12">
                <line x1="0" y1="6" x2="6" y2="6" stroke="rgb(120, 156, 178)" />
                <polygon points="6,1 11,6 6,11" fill="rgb(120, 156, 178)" />
              </svg>
            </div>
            <p>Water direction</p>
          </div>
        </div>

        <!--Legenda Mappa Home-->
        <div v-if="router.currentRoute.value.name === 'home'">
          <div class="legend-item">
            <div class="legend-color blue"></div>
            <p>WTP</p>
          </div>
          <div class="legend-item">
            <div class="legend-arrow">
              <svg width="12" height="12" viewBox="0 0 12 12">
                <line x1="0" y1="6" x2="6" y2="6" stroke="rgb(120, 156, 178)" />
                <polygon points="6,1 11,6 6,11" fill="rgb(120, 156, 178)" />
              </svg>
            </div>
            <p>Water direction</p>
          </div>
          <div class="legend-item">
            <div class="legend-color red"></div>
            <p>Sampled points</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const showModal = ref(true);

</script>


<style>
@import '../assets/styles/mapLegend.css';
</style>