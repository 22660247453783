import { defineStore } from 'pinia'
import { API_BASE_URL } from '../../config.js';


//torna un oggetto di questo tipo: 
//{
//    "sampleDates": [
//      "2023-11-08",
//      "2023-07-24",
//      "2023-07-17",
//      "2023-07-10",
//      "2023-03-27",
//      "2023-03-20",
//      "2023-03-13"
//    ],
//    "samplesCount": 7
//  }
export const useSampleDateStore = defineStore({
  id: 'sample_date',
  state: () => ({
    sample_dates: [],
    loading: false,
    error: null
  }),
  getters: {
  },
  actions: {
    async getSamplesData(){
        this.loading = true;
        try{
        const res = await fetch(API_BASE_URL + '/api/samples');
        const data = await res.json();
        if(res['status'] != 200 || data['detail']){
          throw("errore");
        }
        this.sample_dates = data['sampleDates'];
        }catch(e){
            this.error = "errore";
            throw(e);
        }finally{
          this.loading = false;
        }
    }
  }
})