import { defineStore } from 'pinia'
import { API_BASE_URL } from '../../config.js';

//campaigns => array di oggetti:
//{
//  "campaignID": 1,
//  "risks": [
//    {
//      "nodeID": 0,
//      "risk_index": 1.1
//    },
export const useRiskStore = defineStore({
  id: 'risk',
  state: () => ({
    risk:{},
    loading: false,
    error: null
  }),
  getters: {
    countRisks(){
        return this.risk['risks'].length;
    }
  },
  actions: {
    async getRiskCampaignData(campaign_id) {
      this.loading = true;

      try {
        const res = await fetch(API_BASE_URL + '/api/bayes/risks/' + campaign_id);

        const data = await res.json();
        this.risk = data;

        if (res['status'] != 200 || data['detail']) {
          throw (data['detail']);
        }

      } catch (e) {
        this.error = e;
        throw (this.error);

      } finally {
        this.loading = false;
      }
    }
  }
})