import { defineStore } from 'pinia'
import { API_BASE_URL } from '../../config.js';

export const useExamStore = defineStore({
  id: 'exam',
  state: () => ({
    exams:{},
    exam: null,
    loading: false,
    error: null
  }),
  getters: {
    countExams(){
        return this.exams['labExams'].length;
    },
  }, 
  actions: {
    async getExamsByDate(exam_id){
      this.loading = true;
      try{
          const res = await fetch(API_BASE_URL + '/api/examscampaign/' + exam_id);
  
          const data = await res.json();
  
          if(res['status'] != 200 || data['detail']){
              throw("errore");
          }
          this.exams = data;
          }catch(e){
              this.error = e;
              alert(this.error );
          }finally{
              
          this.loading = false;
          }
    },
    resetInstance(){
      this.exams=[];
      this.exam= null;
      this.loading= false;
      this.error= null;
    }
  },
})