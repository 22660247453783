<template>
    <div class="flex flex-col xl:flex-row h-screen bg-blue-200">
        <div class="w-full p-4">
            <div class="flex justify-end mb-2">
                <button @click="openModal()"
                    class="px-4 py-2 bg-blue-500 text-white rounded-lg transition hover:bg-blue-600">
                    <i class="fa fa-square-plus" style="padding:5px;margin-right:10px;"></i>Aggiungi Esami
                </button>
            </div>
            <div v-if="displayModal" class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                <div class="bg-white p-6 rounded-lg  max-h-[300]">
                    <h2 class="text-lg font-semibold mb-4 flex items-center">
                        {{ examIDToEdit == null ? 'Aggiungi ' : 'Modifica ' }} Esame <small v-if="!examIDToEdit"
                            class="ml-2"></small>
                        <button v-if="!examIDToEdit"
                            class="ml-2 p-1 h-6 border rounded-full bg-blue-500 text-white flex items-center"
                            @click="addExam">
                            <i class="fas fa-plus text-sm"></i>
                        </button>
                        <div class="spazio-vuoto"></div>
                        <p v-if="addNewExamToListError === -1" class="error-text"> Devi selezionare la data e il punto ! </p>
                        <i class="fa fa-close" style="cursor: pointer; margin-left: 10px;" title="Chiudi" @click=closeModal></i>
                    </h2>
                    <form @submit.prevent="handleSubmit" style="max-height: 550px; overflow-y: auto; ">
                        <div class="flex items-center space-x-4">

                            <!-- DATA DEL SAMPLE => è UN DROPDOWN -->
                            <div>
                                <label for="data" class="text-sm font-medium text-gray-700 w-24">Data:</label>
                                <div class="flex items-center">
                                    <select id="data" v-model="selectedDateToAdd"
                                        @change="handleDataChange()" required
                                        class="p-2 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300">
                                        <option v-for="date in sampleDateStore.sample_dates" :key="date" :value="date">
                                            {{ date }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <!-- DROPDOWN DEI PUNTI => MOSTRO SOLAMENTE QUELLI DELLA DATA SPECIFICATA !-->
                            <div class="flex items-start flex-col">
                                <label for="point" class="text-sm font-medium text-gray-700 text-left">Sample
                                    ID:</label>
                                <select id="point" v-model="selectedSampleCollIDToAdd" required
                                    class="p-2 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300">
                                    <option v-if="!selectedDateToAdd" disabled>Punti non disponibili</option>
                                    <option v-else v-for="sample in samplesListStore.sample_by_dates"
                                        :key="sample.id" :value="sample.id">
                                        {{ sample.sampleID }}

                                    </option> <!-- 
                                                     se io cambio data, nella lista quel valore non esiste più ! quindi non me lo mostra .
                                                     devo ggiungere un if => se l'elemento non c'è, allora sicuramente mi vado a prendere il valore corrispondenre
                                                 -->
                                </select>
                            </div>
                        </div>
                        <br>
                        <div v-for="(exam, index) in examsToAdd" :key="index" class="mb-4 flex flex-col">
                            <label class="font-bold mb-2 flex items-start"
                                :style="{ 'justify-content': addNewExamToListError === index ? 'space-between' : 'flex-start' }">Esame
                                {{ index + 1 }}
                                <button v-if="index > 0"
                                    class="ml-2 p-1 h-6 border rounded-full bg-red-500 text-white flex items-center"
                                    @click="removeExam(index)">
                                    <i class="fas fa-minus text-sm"></i>
                                </button>
                                <div class="spazio-vuoto"></div>
                                <p v-if="addNewExamToListError === index" class="error-text"> Devi inserire il valore e il
                                    nome dell'esame ! </p>
                            </label>
                            <br>

                            <!-- DROPDOWN DEGLI ESAMI, SONO I SOLITI 6 -->
                            <!-- Selezionando un valore, mi popola l'altro dropdown con l'unità di misura corrispondente -->

                            <div class="grid grid-cols-3 gap-4">
                                <div class="flex flex-col">
                                    <label :for="'nomeEsame-' + index" class="text-sm font-medium text-gray-700 mb-2">Nome:</label>
                                    <select :id="'nomeEsame-' + index" v-model="exam.nomeEsame"
                                        @change="handleEsameChange(index, exam.nomeEsame)" required
                                        class="p-2 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300">
                                        <option v-for="analisi in tipo_analisi" :key="analisi.id" :value="analisi.name">
                                            {{ analisi.name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="flex flex-col">
                                    <label for="valoreEsame" class="text-sm font-medium text-gray-700 mb-2">Valore:</label>
                                    <select v-if="exam.nomeEsame === 'Presenza COVID'" v-model="exam.valoreEsame" required
                                    class="p-2 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300">
                                        <option value="1"> SI </option>
                                        <option value="0"> NO  </option>
                                    </select>
                                    <input v-else type="text" id="valoreEsame" v-model="exam.valoreEsame" required
                                        class="p-2 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300">
                                </div>

                                <div class="flex flex-col">
                                    <label :for="'unitaEsame-' + index" class="text-sm font-medium text-gray-700 mb-2">Unità:</label>
                                    <input type="text" :id="'unitaEsame-' + index" v-model="exam.unitaEsame" disabled
                                        class="p-2 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300">
                                </div>
                            </div>


                            <!-- CAMPO CUSTOM -->
                            <div class="flex flex-col">
                                <label :for="'customField-' + index"
                                    class="text-sm font-medium text-gray-700 mb-2">Custom</label>
                                <textarea :id="'customField-' + index" v-model="exam.customField" placeholder="Type. . . "
                                    style="border:solid 1px black; padding: 5px;"></textarea>
                            </div>

                            <!-- CAMPO NOTE -->
                            <div class="flex flex-col">
                                <label :for="'noteField-' + index"
                                    class="text-sm font-medium text-gray-700 mb-2">Note</label>
                                <textarea :id="'noteField-' + index" v-model="exam.noteField" placeholder="Type. . . "
                                    style="border:solid 1px black; padding: 5px;"></textarea>
                            </div>

                            <!-- ID DEL LABORATORIO-->
                            <div class="flex flex-col">
                                <label :for="'labId-' + index" class="text-sm font-medium text-gray-700 mb-2">Lab Id</label>
                                <select :id="'labId-' + index" v-model="exam.labId" required
                                    class="p-2 border rounded-md w-48 focus:outline-none focus:ring focus:border-blue-300">
                                    <option v-for="laboratorio in labsStore.labs" :key="laboratorio.id"
                                        :value="laboratorio.id">
                                        {{ laboratorio.name }}
                                    </option>
                                </select>
                            </div>

                            <hr class="mt-3" style="border: 1px solid #000;">
                        </div>

                        <div class="flex justify-end space-x-4">
                            <button type="button"
                                class="px-2 py-1 bg-red-500 text-white rounded-lg transition hover:bg-red-600 text-sm w-16"
                                @click="closeModal">
                                Annulla
                            </button>
                            <button type="submit"
                                class="px-2 py-1 bg-green-500 text-white rounded-lg transition hover:bg-blue-600 text-sm w-16">
                                Salva
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <!--Pulsanti di navigazione della tabella-->
            <div class="flex justify-start mb-3 space-x-4 h-[15px]">
                <button @click="prevPage" :disabled="currentPage === 1"
                    :class="currentPage === 1 ? 'text-grey-500' : 'text-blue-500'">
                    <i class="fas fa-chevron-left"></i> Precedente
                </button>
                <button @click="nextPage" :disabled="currentPage * limit >= examListStore.countExams"
                    :class="currentPage * limit < examListStore.countExams ? 'text-blue-500' : 'text-grey-500'">
                    Successivo <i class="fas fa-chevron-right"></i>
                </button>
            </div> 
            <div class="overflow-x-auto bg-gray-100 rounded-lg shadow-md flex-grow">
                <table class="min-w-full bg-white border rounded-lg shadow-md">
                    <thead>
                        <tr class="bg-gray-100 border-b">
                            <th class="p-4 text-left"> Data <i :class="'fas fa-caret-' + (dataFilter ? 'down' : 'up')" @click="toggleDateFilter" style="cursor: pointer;" title="Ordina per data"></i></th>
                            <!-- <th class="p-4 text-left">Stato</th> -->
                            <th class="p-4 text-left">Laboratorio <i :class="'fas fa-caret-' + (roleFilter ? 'down' : 'up')" @click="toggleRoleFilter" style="cursor: pointer;" title="Ordina per laboratorio"></i></th>
                            <th class="p-4 text-left">Sample ID <i :class="'fas fa-caret-' + (sampleFilter ? 'up' : 'right')" @click="togglSampleFilter" style="cursor: pointer;" title="Filtra per campione"></i>
                                <br>
                                <input v-if="sampleFilter" type="text" v-model="sampleIdFilter" class="p-1 border rounded-md w-24 focus:outline-none focus:ring focus:border-blue-300">
                            </th>
                            <th class="p-4 text-left">Point ID <i :class="'fas fa-caret-' + (pointFilter ? 'up' : 'right')" @click="togglePointFilter" style="cursor: pointer;" title="Filtra per punto"></i>
                                <br>
                                <input v-if="pointFilter" type="text" v-model="pointIdFilter" class="p-1 border rounded-md w-24 focus:outline-none focus:ring focus:border-blue-300">
                            </th>
                            <th class="p-4 text-left">Nome</th>
                            <th class="p-4 text-left">Unità</th>
                            <th class="p-4 text-left">Valore</th>
                            <th class="p-4 text-left">Azioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b" v-for="exam in displayedExams" :key="exam.id">
                            <td class="p-4 w-[200px]"> {{ exam.examDate }} </td>
                            <!--  <td class="p-4"> {{ exam.status }} </td> -->
                            <td class="p-4"> {{ exam.labID }} </td>
                            <td class="p-4"> {{ exam.sampleID }} </td>
                            <td class="p-4"> {{ exam.netPoints }} </td>
                            <td class="p-4"> {{ exam.examName }} </td>
                            <td class="p-4" style="position: relative; cursor: pointer;" :id="'examvalue-' + exam.id"> 
                            <div class="hover-overlay" v-on:mouseover="showOverlay(exam.valueUnit)" v-on:mouseout="hideOverlay()"> {{ overlayValue }}</div> 
                                 {{ exam.valueUnit.substring(0, 7) }} 
                            </td>
                            <td class="p-4"> {{ exam.examValue }} </td>
                            <td class="p-4" style="justify-content: space-around; display: flex;">
                                <i class="fa-solid fa-edit" title="modifica esame" style="cursor: pointer; color: green;"
                                    @click="openModal(exam)"></i>
                                <i class="fa-solid fa-trash" title="Elimina esame" style="cursor: pointer; color: red"
                                    @click="openDeleteModal(exam)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import { ref, reactive, computed } from 'vue';
import { useExamsListStore } from '../stores/data_entry/exams_list';
import { useSamplesListStore } from '../stores/data_entry/samples_list';
import { useSampleDateStore } from '../stores/sample_date';
import { useLabsStore } from "../stores/data_entry/labs";
import { useAuthStore } from "../stores/auth";
import Swal from 'sweetalert2';
import tipo_analisi from "../data/analisysTypes.json";


const examListStore = useExamsListStore();

//le date principali (selezionabili dal dropdown)
const sampleDateStore = useSampleDateStore();
const labsStore = useLabsStore();

//per ottenere i sample in base alla data selezionata !
const samplesListStore = useSamplesListStore();

const apiCallSuccess = ref(false);

const apiSampleDateCallSuccess = ref(false);
//chiamata API ai laboratori
const labsCallSuccess = ref(false);
const displayModal = ref(false);

const examsToAdd = ref([]);

const selectedDateToAdd = ref(null);

const selectedSampleCollIDToAdd = ref(null);

const currentPage = ref(0);
const limit = ref(8);


//filtro per data
const dataFilter = ref(false);

//filtro per ruolo
const roleFilter = ref(false);

const pointFilter = ref(false);
const pointIdFilter = ref('');

const sampleFilter = ref(false);
const sampleIdFilter = ref('');

//mostra il valore dell'unità in hover
const overlayValue = ref('');

//-1 => errore mostrato in alto
// da 0 in poi => errore mostrato nell'indice di riferimento
// -2 => nessun errore
const addNewExamToListError = ref(-2);

let examIDToEdit;

//variabile in cui inserisco l'exam  da eliminare
let examIDToDelete;

const defaultExam = reactive({
    nomeEsame: '',
    valoreEsame: '',
    unitaEsame: '', // sarebbe examValue
    customField: '',
    noteField: '',
    labId: '',
    examID: '',
});

const openModal = (exam) => {

    if (!exam) { //allora sto creando nuovi esami
        examsToAdd.value = [JSON.parse(JSON.stringify(defaultExam))];
    } else { //voglio modificare un esame
        examIDToEdit = exam.id;
        selectedDateToAdd.value = exam.examDate;
        selectedSampleCollIDToAdd.value = exam.sampleID;
        handleDataChange(exam.sampleID);
        examsToAdd.value = [JSON.parse(JSON.stringify({
            nomeEsame: exam.examName,
            valoreEsame: exam.examValue,
            unitaEsame: exam.valueUnit,
            customField: exam.customField,
            noteField: exam.noteField,
            examID: exam.examID,
            labId: labsStore.getLabByName(exam.labID)
        }))];
    }
    displayModal.value = true;
};

const closeModal = () => {
    displayModal.value = false;
    examsToAdd.value = [];
    examIDToEdit = null;
    addNewExamToListError.value = -2;
    selectedDateToAdd.value = null;
    selectedSampleCollIDToAdd.value = null;
};

const openDeleteModal = (exam) => {
    examIDToDelete = exam.id;
    Swal.fire({
        title: "Sei sicuro di voler eliminare questo esame ?",
        text: "L'operazione è irreversibile !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Elimina esame!"
    }).then((result) => {
        if (result.isConfirmed) { //ha premuto il tasto elimina
            cancelExam();
        }
    });
};

const addExam = () => {
    //prima di aggiungere un nuovo esame alla lista
    //controllo se sugli esami inseriti i dati obbligatori siano stati compilati correttamente !
    addNewExamToListError.value = -2;
    if(!selectedDateToAdd.value || !selectedSampleCollIDToAdd.value){
        //TODO => GESTIRE SE NON SELEZIONO LA DATA O IL PUNTO !
        addNewExamToListError.value = -1;
        return;
    }
    for (let i = 0; i < examsToAdd.value.length; i++) {
        if (!examsToAdd.value[i].nomeEsame || !examsToAdd.value[i].valoreEsame) {
            //appare un testo rosso nel field di riferimento: devi compilare nome e valore !
            addNewExamToListError.value = i;
            return;
        }
    }
    examsToAdd.value.push(JSON.parse(JSON.stringify(defaultExam)));
};

const removeExam = (index) => {
    examsToAdd.value.splice(index, 1);
};


examListStore
    .getExamsList()
    .then(() => {
        apiCallSuccess.value = true;
        currentPage.value = 1;
    })
    .catch(() => {
        apiCallSuccess.value = false;
        currentPage.value = 1;
    });

labsStore.getLabsList()
    .then(() => {
        labsCallSuccess.value = true;
    })
    .catch(() => {
        labsCallSuccess.value = false;
    });

//ottengo le date principali dei samples => le uso nel dropdown di scelta della data
sampleDateStore.getSamplesData()
    .then(() => {
        apiSampleDateCallSuccess.value = true;
    })
    .catch(() => {
        apiSampleDateCallSuccess.value = false;
    });


const handleDataChange = (sampleID) => {
    //OTTENGO I SAMPLE DI QUESTA DATA
    samplesListStore.getSamplesByDate(selectedDateToAdd.value)
        .then(() => {
            //apiSampleDateCallSuccess.value = true;
            if (examIDToEdit) { //sto modificando un esame, aggiorno l'id del netpoint
                selectedSampleCollIDToAdd.value = samplesListStore.getSampleDatesByName(sampleID);
            }
        })
        .catch(() => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
            });
        });
};

const handleEsameChange = (index, esameName) => {
    //scelto il nome dell'esame, vado a popolare  'unitaEsame' con il valueUnit corrispondente
    //cerco l'unità di misura dell'esame in base al nome
    const examValueUnit = tipo_analisi.find(item => {
        if (item.name === esameName) {
            return item['valueUnit'];
        }
    });
    //inserisco il valore nell'input unitaEsame
    examsToAdd.value[index].unitaEsame = examValueUnit['valueUnit'];
    examsToAdd.value[index].examID = examValueUnit['id'];
};


//quando si vuole salvare o modificare un nuovo esame
const handleSubmit = () => {
    const authStore = useAuthStore();
    if (examIDToEdit) { //procedo con la modifica

        //TODO => LA PUT MI DA PROBLEMI !
        examListStore.updateExam(examIDToEdit, authStore.access_token, examsToAdd.value[0], selectedSampleCollIDToAdd.value)
            .then(() => {
                Swal.fire({
                    icon: "success",
                    text: "Esame aggiornato con successo !",
                })
                    .then(() => {
                       // window.location.reload();
                        closeModal();
                    });
            })
            .catch(() => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Errore nella modifica !",
                });
                closeModal();
            });
    } else { //procedo con la creazione degli esami
        examListStore.createExams(authStore.access_token, examsToAdd.value, selectedDateToAdd.value, selectedSampleCollIDToAdd.value)
            .then(() => {
                Swal.fire({
                    icon: "success",
                    text: "Esame creato con successo !",
                }).then(() => {
                    window.location.reload();
                    closeModal();
                });
            })
            .catch(() => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Errore: esami non inseriti !",
                });
                closeModal();
            });
    }
};


const cancelExam = () => {
    const authStore = useAuthStore();
    examListStore.deleteExam(examIDToDelete, authStore.access_token)
        .then(() => {
            Swal.fire({
                icon: "success",
                text: "Eliminato con successo !",
            }).then(() => {
                window.location.reload();
                closeModal();
            });
        })
        .catch(() => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Qualcosa è andato storto !",
            });
        });
};


//FILTRI
const toggleDateFilter = () => {
    examListStore.exams.reverse();
    dataFilter.value = !dataFilter.value;
};

const toggleRoleFilter = () => {
    roleFilter.value = !roleFilter.value;
    examListStore.sortElementsByLab(roleFilter.value);
};

const togglePointFilter = () => {
    sampleFilter.value = false;
    sampleIdFilter.value = '';
    pointFilter.value = !pointFilter.value;
    pointIdFilter.value = '';
};

const togglSampleFilter = () => {
    pointIdFilter.value = '';
    pointFilter.value = false;
    sampleFilter.value = !sampleFilter.value;
    sampleIdFilter.value = '';
};




const displayedExams = computed(() => {
    const startIndex = (currentPage.value - 1) * limit.value;
    const endIndex = startIndex + limit.value;

    //filtro per punto
        if(pointFilter.value){
            const filteredExams = examListStore.exams.filter(exam => {
                return exam.netPoints.toLowerCase().includes(pointIdFilter.value.toLowerCase());
            });
            return filteredExams.slice(startIndex, endIndex);
        }

    //filtro per sample
        if(sampleFilter.value){
            const filteredExams = examListStore.exams.filter(exam => {
                return exam.sampleID.toLowerCase().includes(sampleIdFilter.value.toLowerCase());
            });
            return filteredExams.slice(startIndex, endIndex);
        }

        //non ci sono filtri
            return examListStore.exams.slice(startIndex, endIndex);
        
});

//FUNZIONI PER NAVIGAZIONE TABELLA
const prevPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
};

const nextPage = () => {
    if (currentPage.value * limit.value < examListStore.countExams) {
        currentPage.value++;
    }
};


//Overlay che mostra il valore dell'unità in on-hover
const showOverlay = (value) => {
    overlayValue.value = value;
};

const hideOverlay = () => {
    overlayValue.value = '';
};


</script>



<style scoped>
@import '../assets/styles/optimizationHistory.css';

.spazio-vuoto {
    flex-grow: 1;
}

.error-text {
    padding-left: 6px;
    padding-right: 6px;
    color: red;
    background-color: #fcb1b1;
}



.hover-effect {
    cursor: pointer;
  }

.hover-overlay {
  position: absolute;
  width: 200%;
  height: 100%; 
  background-color: lightgray;
  display: none;
  z-index: 1; 
}
td:hover .hover-overlay {
  display: block;
  color: black;
}


</style>