import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RiskView from '../views/RiskView.vue'
import OptimizationView from '../views/OptimizationView.vue'
import LoginComponent from '../components/LoginComponent.vue'
import SampleView from '../views/SampleView.vue';
import ExamView from '../views/ExamView.vue';
import { useAuthStore } from "../stores/auth";
import Swal from 'sweetalert2';
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "WBE-Covid",
    },
    props: route => ({
      logout: route.query.logout === 'true'
    }),
  },
  {
    path: '/analisi-rischio',
    name: 'risk',
    component: RiskView,
    meta: {
      title: "WBE-Covid Risks", 
    }
  },
  {
    path: '/ottimizzazione',
    name: 'ott',
    component: OptimizationView,
    meta: {
      title: "WBE-Covid Ottimizzazione",
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'log',
    component: LoginComponent,
    meta:{
      title: "WBE-Covid Login",
    }
  },
  {
    path: '/sample',
    name: 'sample',
    component: SampleView,
    meta: {
      title: "WBE-Covid Sample",
      requiresAuth: true, 
    }
  },{
    path: '/exam',
    name: 'exam',
    component: ExamView,
    meta: {
      title: "WBE-Covid Exam",
      requiresAuth: true,
    }
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  document.title = to.meta.title;
  const richiedeAutenticazione = to.meta.requiresAuth;
  if (richiedeAutenticazione && !authStore.isAuthenticated) {
    //L'utente non è autenticato e si richiede autenticazione,
    //reindirizza alla pagina di login
    return next({ name: 'log' });
  }
  const isLogout = to.query.logout
  if (isLogout) {
    authStore.logout();
    //Reindirizza l'utente alla pagina Home
    Swal.fire({
      icon: "success",
      text: "Logout eseguito con successo !",
  }).then(() => {
         return next({ name: 'home' });
      });
  }
    //L'utente è autenticato o non richiede autenticazione,
    //prosegui la navigazione
   next();
  
});

export default router
