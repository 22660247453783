import { defineStore } from 'pinia'
import { API_BASE_URL } from '../../config.js';


export const useOptimizationStore = defineStore({
    id: 'optimization',
    state: () => ({
        optimization: {},
        error: null,
        isLoading: false,
        successOptimization: false
    }),
    getters: {

    },
    actions: {
        async getOptimizationCampaignData(campaign_id, offset = 0) {
            // this.isLoading = true;

            //const queryParams = new URLSearchParams({
            //    campaign_id: campaign_id.toString(),
            //    offset: offset.toString()
            //});
            let queryParams = '';

            if (campaign_id != null) {
                queryParams += '?campaign_id=' + campaign_id;
            }

            if (offset > 0) {
                queryParams += '$offset=' + offset;
            }
            try {
                const res = await fetch(`${API_BASE_URL}/api/optimization` + queryParams);

                const data = await res.json();
                this.optimization = data;
                if (this.optimization.length < 1) {
                    //empty
                    this.error = "Non ci sono risultati";
                }
            } catch (e) {
                this.error = e;
                throw this.error;

            } finally {
                this.isLoading = false;
            }
        },

        async runOptimization(campaign_id, n_teams, n_samples, gamma, token) {

            this.isLoading = true;
            // this.successOptimization = false;
            // const authStore = useAuthStore();

            const headers = {'Authorization': 'Bearer ' + token,'Content-Type': 'application/json','Accept': 'application/json'};

            let queryParams = '';

            if (n_samples != null) {

                queryParams += 'n_samples=' + n_samples;
            }

            if (n_teams != null) {

                queryParams += '&n_teams=' + n_teams;
            }

            if (gamma != null) {

                queryParams += '&gamma=' + gamma;
            }

            try {
                await fetch(`${API_BASE_URL}/api/runoptimization/${campaign_id}?${queryParams}`, {
                    headers: headers,
                    method: 'POST'

                });

                await new Promise((resolve) => {
                    this.successOptimization = true;

                    setTimeout(() => {
                        resolve();
                    }, 5000);
                });

                await this.getOptimizationCampaignData(campaign_id);
            } catch (e) {

                this.error = 'Si è verificato un errore sconosciuto.';
                throw this.error;

            } finally {

                this.isLoading = false;
                this.successOptimization = false;
            }

        }



    }
})