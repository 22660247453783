import { defineStore } from 'pinia'
import { DATA_ENTRY_URL } from '../../../config.js';


//endpoint: /examslist => store con cui gestisco la tabella in ExamView

//la GET ritorna un array, ogni elemento è fatto così:
//{
//    "valueUnit": "NA",
//    "sampleID": "ITE003X300420230313ARPA0009",
//    "examName": "Presenza COVID",
//    "labID": "ARPA",
//    "customField": "",    => non visibile in tabella
//    "created_by": 2,
//    "updated_by": 2,
//    "status": "N",
//    "id": 1,           => non visibile in tabella
//    "examValue": 1,
//    "netPoints": "E003X3004",
//    "examDate": "2023-03-13",
//    "noteField": "",
//    "created_at": "2023-03-13T08:00:00",
//    "updated_at": "2023-03-16T08:00:00",
//    "role": "admin"
//},


//per l'update passare questi dati:
//{
//  "examID": 0,
//  "examValue": 0,
//  "customField": "string",
//  "noteField": "string",
//  "sampleCollID": 0,
//  "labID": 0
//}


export const useExamsListStore = defineStore({
  id: 'exams_list',
  state: () => ({
    exams: [],
    isLoading: false,
    error: null,

  }),
  getters: {
    countExams() {
      return this.exams.length;
    },
  },
  actions: {
    //per ora mi torna i + vecchi, chiedere ad Andrea se può passarceli in ordine inverso (così ci manda i + recenti !)
    async getExamsList(limit) {
      this.loading = true;
      let queryParams = '';
      if (limit != null) {
        queryParams = 'limit=' + limit;
      }
      try {
        const res = await fetch(DATA_ENTRY_URL + '/examslist?' + queryParams);

        const data = await res.json();
        if (res['status'] != 200 || data['detail']) {
          throw ("errore");
        }
        this.exams = data;
      } catch (e) {
        this.error = e;
        throw (e);
      } finally {

        this.loading = false;
      }
    },
    async createExams(user_acc_token, exams, exam_data, exam_sample_collID) {
      this.loading = true;

      const headers = {
        'Authorization': 'Bearer ' + user_acc_token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };

      const body = {
        "sampleCollID": exam_sample_collID,
        "labID": 1,        //cosa ci va qua dentro ?
        "exams": exams.map(item => ({
          "examID": item.examID, //cosa ci va qua dentro ?
          "examValue": item.valoreEsame,
          "noteField": item.noteField, //può essere null
          "customField": item.customField, //può essere null
          "examDate": exam_data,
          "valueUnit": exam_data.unitaEsame,
          "examName": item.nomeEsame
        })),
      };

      try {

        const res = await fetch(`${DATA_ENTRY_URL}/newexam`, {
          headers: headers,
          body: JSON.stringify(body),
          method: "POST"
        });
        if (!res['ok']) {
          throw new Error("errore");
        }
      } catch (e) {
        this.error = e;
        throw (e);
      } finally {
        this.loading = false;
      }
    },
    async updateExam(exam_id, user_acc_token, exam_data, exam_sample_collID) {
      this.loading = true;
       const headers = {
         'Authorization': 'Bearer '+ user_acc_token,
         'Content-Type': 'application/json',
         'Accept': 'application/json'
       };
     const body = {
       "examID": exam_id,
       "examValue": exam_data.valoreEsame,
       "customField":exam_data.customField, 
       "noteField": exam_data.noteField,
       "sampleCollID": exam_sample_collID,
       "examName": exam_data.nomeEsame,
       "valueUnit": exam_data.unitaEsame,
       "labID": exam_data.labId
     };
      
      try{
        const res = await fetch(`${DATA_ENTRY_URL}/updateexam/${exam_id}`, {
          headers: headers,
          body: JSON.stringify(body),
          method: "PUT"
        });
      
          if(!res['ok']){
              throw new Error("errore");
          }
          }catch(e){
              this.error = e;
              throw(e);
          }finally{
          this.loading = false;
          }
    },

    async deleteExam(exam_id, user_acc_token) {
      this.loading = true;
      const headers = {
        'Authorization': 'Bearer ' + user_acc_token,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };
      try {

        const res = await fetch(`${DATA_ENTRY_URL}/deleteexam/${exam_id}`, {
          headers: headers,
          method: "DELETE"
        });

        if (!res['ok']) {
          throw new Error("errore");
        }
      } catch (e) {
        this.error = e;
        throw (e);
      } finally {
        this.loading = false;
      }
    },
    sortElementsByLab(filterByArpa) {
      let filter = filterByArpa ? 'ARPA' : 'CNR';
      return this.exams.sort((a, b) => {
        if (a.labID === filter && b.labID !== filter) {
          return -1;
        } else if (a.labID !== filter && b.labID === filter) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    resetInstance() {
      this.exams = [];
      this.loading = false;
      this.error = null;
    }
  },
});