<template>
  <div class="relative flex items-end space-x-2">
    <label class="block font-bold mb-1 justify-start" for="date-range">Selezionare Data Analisi:<br></label>
    <select v-model="selectedDateRange"
      class="w-2/4 px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300 shadow-md"
      @change="handleSearch">

      <option v-if="selectedDateRange === ''" value="" disabled selected><i>Selezionare una data</i></option>
      <option v-for="campagna in campagneStore.campagne" :key="campagna.id" :value="campagna.id">{{ campagna.date }}
      </option>
    </select>
  </div>
  <br>
</template>

  
<script setup>
import { ref, watch, defineOptions } from 'vue';
import { useCampagneStore } from '../stores/campagne';
import { useExamStore } from "../stores/exam";
import { useNodeStore } from "../stores/node";
import { useLoaderStore } from '../stores/loader';
import { useRiskStore } from '../stores/risk';
import { useOptimizationStore } from '../stores/optimization';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
defineOptions({
  inheritAttrs: false, //non prendo attributi passati dal componente padre => evito il warning
})

const loaderStore = useLoaderStore();
const campagneStore = useCampagneStore();
const examStore = useExamStore();
const nodeStore = useNodeStore();
const riskStore = useRiskStore();
const optimizationStore = useOptimizationStore();
const router = useRouter();
const selectedDateRange = ref('');

const handleSearch = async () => {
  if (selectedDateRange.value) {
    campagneStore.camp_selezionata_id = selectedDateRange.value;
    loaderStore.isLoading = true;
    nodeStore.initNodes();

    //LA CHIAMATA CAMBIA IN BASE ALLA PAGINA IN CUI SIAMO:
    //SE siamo su home page => prendo le analisi fatte sui nodi in base alla data
    //Se siamo su risk page => prendo i valori di rischio per ogni nodo !

    if (router.currentRoute.value.name === 'home') {

      examStore.getExamsByDate(selectedDateRange.value).then(() => {
        for (let i = 0; i < examStore.countExams; i++) {
          let exam = examStore.exams['labExams'][i];
          let success_updated_node = nodeStore.updateCurrentNode(exam['nodeID']);
          //se errore, nodo non trovato => allora proseguo il ciclo
          if (!success_updated_node) continue;
          nodeStore.addNodeAnalisis(exam['exams'], examStore.exams['examDate']);
        }

        nodeStore.resetNode();
        loaderStore.isLoading = false;
      });
      return;
    }


    if (router.currentRoute.value.name === 'risk') {

      riskStore.getRiskCampaignData(selectedDateRange.value)
        .then(() => {
          for (let i = 0; i < riskStore.countRisks; i++) {
            //esempio di cur_risk => {'nodeID':0, 'risk_index':1.1}
            let cur_risk = riskStore.risk['risks'][i];
            let success_updated_node = nodeStore.updateCurrentNode(cur_risk['nodeID']);
            if (!success_updated_node) continue;
            //aggiungo attributo al nodo e il colore
            nodeStore.addRiskProperty();
            nodeStore.addNodeRisk(cur_risk['risk_index']);
          }
          nodeStore.resetNode();
          loaderStore.isLoading = false;
        })
        .catch(() => Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Qualcosa è andato storto",
            }));
      return;
    }


    if (router.currentRoute.value.name === 'ott') {
      await optimizationStore.getOptimizationCampaignData(selectedDateRange.value);
      loaderStore.isLoading = false;
      return;
    }

  }
};

if (campagneStore.campagne.length > 0) {
  selectedDateRange.value = campagneStore.campagne[campagneStore.campagne.length - 1]['id'];
  handleSearch();
}

//quando ho preso le date => rigiro l'array (prendo quella + recente) e mando handleSearch
watch(
  () => campagneStore.campagne,
  () => {
    selectedDateRange.value = campagneStore.campagne[campagneStore.campagne.length - 1]['id'];
    handleSearch();
  });
</script>

