// store.js
import { defineStore } from 'pinia';

export const useLoaderStore = defineStore({
  id: 'loader', // identificatore univoco per lo store
  state: () => ({
    isLoading: false,
  }),
  // ... altre sezioni come actions, getters, mutations, ecc. ...
});
