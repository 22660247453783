import { defineStore } from 'pinia'
import { DATA_ENTRY_URL } from '../../../config.js';


//endpoint: /sampleslist => store con cui gestisco la tabella in SampleView

//la GET ritorna un array, ogni elemento è fatto così:
//{
//  "sampleDate": "2023-03-13",
//  "id": 195,
//  "sampleType": "Fixed",
//  "status": "N",
//  "created_at": "2023-03-13T08:00:00",
//  "updated_at": "2023-03-16T08:00:00",
//  "sampleID": "ITE003X300420230313ARPA0009",
//  "pointID": "E003X3004",
//  "sampleTime": "09:59:00",
//  "role": "ARPA",
//  "created_by": 2,
//  "updated_by": 2
//},


// la POST crea un nuovo sample


//endpoint => /samplesIDlist?date= data e ora in formato 00:00
//ritorna un array di sample in base alla data, ogni elemento dell'array è:
//{
//  "id": 301,
//  "sampleID": "ITE003X300420230724ARPA0171"
//},


export const useSamplesListStore = defineStore({
    id: 'samples_list',
    state: () => ({
        samples: [],
        isLoading: false,
        error: null,
        sample_by_dates: []
      }),
      getters: {
        countSamples(){
            return this.samples.length;
        },
      }, 
      actions: {
        //per ora mi torna i + vecchi, chiedere ad Andrea se può passarceli in ordine inverso (così ci manda i + recenti !)
        async getSamplesList(limit){
          this.loading = true;
          let queryParams = '';
          if(limit != null){
            queryParams = 'limit='+limit;
          }
          try{
              const res = await fetch(DATA_ENTRY_URL + '/sampleslist?'+queryParams);
      
              const data = await res.json();
              if(res['status'] != 200 || data['detail']){
                  throw("errore");
              }
              this.samples = data;
              }catch(e){
                  this.error = e;
                  throw(e);
              }finally{
                  
              this.loading = false;
              }
        },
        //passo il nome del sample, mi ritorna il suo id
        getSampleDatesByName(name){
          let sampleId;
           this.sample_by_dates.find(element => {
            if(element.sampleID === name){
              sampleId = element.id;
            }
          });

          return sampleId;
        },

        //mi torna i sample fatti in questa data
        //devo passargli anche l'orario, per ora gli passo 00:00
        async getSamplesByDate(date){
          this.loading = true;
          try{
              const res = await fetch(DATA_ENTRY_URL + '/samplesIDlist?date='+date + '%2000%3A00');
      
              const data_res = await res.json();
              if(res['status'] != 200 || data_res['detail']){
                  throw new Error("errore");
              }
              this.sample_by_dates = data_res;
              }catch(e){
                  this.error = e;
                  throw(e);
              }finally{
                  
              this.loading = false;
              }
        },

        async createSample(user_acc_token, date, time, netPointID, sampleType, labID){
          this.loading = true;

          const headers = {
            'Authorization': 'Bearer '+ user_acc_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'

          };

          const body = {
            "date": date, 
            "time": time, 
            "netPointID":netPointID, 
            "sampleType":sampleType, 
            "labID":labID
          };

          try{

            const res = await fetch(`${DATA_ENTRY_URL}/newsample`, {
              headers: headers,
              body: JSON.stringify(body),
              method: "POST"
            });
      
              if(!res['ok']){
                  throw new Error("errore");
              }
              }catch(e){
                  this.error = e;
                  throw(e);
              }finally{
              this.loading = false;
              }
        },

        async updateSample(sample_id, user_acc_token, date, time, netPointID, sampleType, labID){
          this.loading = true;
          const headers = {
            'Authorization': 'Bearer '+ user_acc_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          };

          const body = {
            "date": date, 
            "time": time, 
            "netPointID":netPointID, 
            "sampleType":sampleType, 
            "labID":labID
          };

          try{

            const res = await fetch(`${DATA_ENTRY_URL}/updatesample/${sample_id}`, {
              headers: headers,
              body: JSON.stringify(body),
              method: "PUT"
            });

              if(!res['ok']){
                  throw new Error("errore");
              }
              }catch(e){
                  this.error = e;
                  throw(e);
              }finally{
              this.loading = false;
              }
        },

        async deleteSample(sample_id, user_acc_token){
          this.loading = true;
          const headers = {
            'Authorization': 'Bearer '+ user_acc_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          };
          try{

            const res = await fetch(`${DATA_ENTRY_URL}/deletesample/${sample_id}`, {
              headers: headers,
              method: "DELETE"
            });

              if(!res['ok']){
                  throw new Error("errore");
              }
              }catch(e){
                  this.error = e;
                  throw(e);
              }finally{
              this.loading = false;
              }
        },

        sortElementsByType(filterByFixed) {
          let filter = filterByFixed ? 'Fixed' : 'Mobile';
          return this.samples.sort((a, b) => {
            if (a.sampleType === filter && b.sampleType !== filter) {
              return -1;
            } else if (a.sampleType !== filter && b.sampleType === filter) {
              return 1;
            } else {
              return 0;
            }
          });
        },
        sortElementsByRole(filterByArpa) {
          let filter = filterByArpa ? 'ARPA' : 'CNR';
          return this.samples.sort((a, b) => {
            if (a.role === filter && b.role !== filter) {
              return -1;
            } else if (a.role !== filter && b.role === filter) {
              return 1;
            } else {
              return 0;
            }
          });
        },
        resetInstance(){
          this.samples=[];
          this.sample_dates = [];
          this.loading= false;
          this.error= null;
        }
      },
});