<template>
  <div class="flex flex-col xl:flex-row bg-blue-200">
    <div class="flex-1 flex flex-col">
      <div class="flex flex-grow p-4">
        <div class="flex-1 flex flex-col xl:flex-row">

          <div class="bg-white rounded-lg shadow-md p-6 xl:mr-4 flex-1 xl:w-1/2">
            <ChartFiltersComponent class="mt-4" />
            <ChartComponent />
          </div>

          <div class="bg-white rounded-lg shadow-md p-6 xl:ml-4 w-full xl:w-1/2 xl:mt-0 mt-4">
            <DateFilterComponent class="mt-4 h-[80vh]" />
            <MapComponent class="h-[90vh]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script setup>
import MapComponent from "../components/MapComponent.vue";
import DateFilterComponent from "../components/DateFilterComponent.vue";
import ChartComponent from "../components/ChartComponent.vue";
import ChartFiltersComponent from "../components/ChartFiltersComponent.vue";

import { useNodeStore } from "../stores/node";
import { useEdgeStore } from "../stores/edge";
import { useCampagneStore } from '../stores/campagne';

const nodeStore = useNodeStore();
const edgeStore = useEdgeStore();
const campagneStore = useCampagneStore();


if (nodeStore.nodes.length < 1 || edgeStore.edges.length < 1 || campagneStore.campagne.length < 1) {
  nodeStore.initNodes();
  edgeStore.initializeEdges();
  campagneStore.getCampagneData();
}
</script>







