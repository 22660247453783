<template>
  <div class="flex-container">
    <div class="flex-item w-2/4">
      <div class="grid grid-cols-1">
        <p><strong>Analisi</strong></p>
        <br>
        <label
          v-for="analisi in tipo_analisi"
          :key="analisi.id"
          class="block radio-label-spacing"
        >
          <input
            type="radio"
            :value="analisi.id"
            v-model="analisi_selezionata"
            @change="mostraGrafico"
          >
          <span class="custom-radio"></span>
          {{ analisi.name }}
        </label>
      </div>
    </div>

    <div class="flex-item-right w-2/4">
      <div class="center-text">
        <p><strong>Stazioni</strong></p>
      </div>
      <br>

      <div class="checkbox-container grid grid-cols-2">
        <label
          v-for="(stazione, index) in nodeStore.getStazioni"
          :key="index"
          :value="stazione"
          class="block label-spacing"
        >
          <input
            type="checkbox"
            :value="stazione.node_id"
            v-model="stazioni_scelte"
            @change="mostraGrafico"
          >
          <span class="custom-checkbox"></span>
          {{ stazione.label }}
        </label>
      </div>
    </div>
  </div>

  <br>
  <br>
</template>






<script setup>
import {ref, watch, defineOptions} from "vue";
import { useNodeStore } from "../stores/node";
import { useGraficoAnalisiStore } from "../stores/grafico_analisi";
import  tipo_analisi  from "../data/analisysTypes.json";

defineOptions({
    inheritAttrs: false, //non prendo attributi passati dal componente padre => evito il warning
  })


const nodeStore = useNodeStore();
const graficoAnalisiStore = useGraficoAnalisiStore();


const analisi_selezionata = ref(1);

const primaStazioneDisponibile = nodeStore.getStazioni[0];

const stazioni_scelte = ref([primaStazioneDisponibile.node_id]);

watch(
  () => stazioni_scelte.value,
  (newVal, oldVal) => {
   if(oldVal.length < newVal.length){ //vuol dire che ho selezionato una nuova stazione
    nodeStore.stazione_corrente = stazioni_scelte.value;
   }
  });

const mostraGrafico = async () => {
  if(analisi_selezionata.value == null){
    return;
  }

  if(stazioni_scelte.value.length < 1){
    //resetta le analisi
    graficoAnalisiStore.resetInstance();
    return;
  }
  
  await graficoAnalisiStore.getAnalisiById(analisi_selezionata.value);
  graficoAnalisiStore.filterAnalisiByNodes(stazioni_scelte.value);
};

mostraGrafico();
</script>


<style>
  @import '../assets/styles/chartFilters.css';
</style>





