<template>
  <div class="relative bg-white rounded-lg mr-4 ml-4 mt-2">
    <!-- Logo e testo "Benvenuto, username" all'interno di un flex container -->
    <div class="flex items-center">
      <!-- Loghi -->
      <div class="flex items-center space-x-4">
        <router-link to="/">
          <div class="logo">
            <img src="../assets/img/logo-wbe.png" alt="Logo" class="w-auto h-12" />
          </div>
        </router-link>
        <router-link to="/">
          <div class="logo">
            <img src="../assets/img/interreg_wbecovid.png" alt="Logo" class="w-auto h-12 pl-2" />
          </div>
        </router-link>
      </div>

      <!--
      <div class="msg_benvenuto_sopra flex-grow space-x-4 text-blue-600" v-if="isUserAuth">
        <p style="text-align:center">Benvenuto, <strong>{{ authStore.username }}</strong></p>
      </div>
    -->
      <!-- Menu desktop -->
      <div class="nomobile hidden md:flex ml-auto space-x-4 text-xl font-bold text-[#000088]">
        <div class="flex items-center space-x-4 justify-around">
          <div>
            <router-link to="/" class="pr-2 transition-colors hover:text-blue-600 font-bold"
              :class="{ 'text-[#0f70b7] hover:text-[#77cdf4] active-link': $route.name === 'home' }">Home
            </router-link>
          </div>
          <div>
            <router-link to="/analisi-rischio" class="pr-2 transition-colors hover:text-blue-600 font-bold"
              :class="{ 'text-[#0f70b7] hover:text-[#77cdf4] active-link': $route.name === 'risk' }">Analisi Rischio
            </router-link>
          </div>
          <!-- ICONA con dropdown per la navigazione e per il login ! -->
          <div :class="'user-icon ' + (showDropdown ? 'circle-disable' : 'circle-active')" @click="toggleDropdown">
            <i class="fa fa-user" aria-hidden="true"></i>
            <div v-if="showDropdown" class="dropdown">
              <div v-if="isUserAuth">
                <div v-for="item in items" :key="item.id">
                  <div v-if="item.name !== 'Logout'">
                    <router-link :to="'/' + item.to" class="pr-2 transition-colors hover:text-blue-600 font-bold "
                      :class="{ 'text-[#0f70b7] hover:text-[#77cdf4] active-link': $route.name === item.route_name }"> {{
                        item.name }}
                    </router-link>
                  </div>
                  <div v-else>
                    <router-link :to="{ path: '/', query: { logout: 'true' } }"
                      class="pr-2 transition-colors hover:text-blue-600 font-bold">
                      Logout
                    </router-link>
                  </div>
                </div>
              </div>
              <div v-else>
                <router-link to="/login" class="pr-2 transition-colors hover:text-blue-600 font-bold"
                  :class="{ 'text-[#0f70b7] hover:text-[#77cdf4] active-link': $route.name === 'login' }"> Login    
                </router-link>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- Icona del menu hamburger solo nella versione mobile -->
      <div class="nodesktop" style="text-align: end;">
        <div @click="toggleMobileMenu" class="cursor-pointer">
          <i v-if="isMobileMenuOpen" class="fas fa-times text-gray-600 text-5xl"></i>
          <i v-else class="fas fa-bars text-gray-600 text-5xl"></i>
        </div>
      </div>
    </div>

    <!-- Menu mobile (nascosto nella versione desktop) -->
    <ul v-if="isMobileMenuOpen" @click="toggleMobileMenu"
      class="nodesktop absolute top-0 right-0 bg-white w-64 h-auto flex flex-col space-y-2 pr-4 pt-3 pb-4 border border-gray-300 text-xl font-bold"
      style="z-index: 1001;">
      <li class="ml-auto cursor-pointer" style="margin-bottom: 0px; margin-top:0px; padding: 0px;">
        <i class="fas fa-times text-blue-200 text-xl"></i>
      </li>
      <li class="ml-2 pr-2 text-blue-500 hover:text-blue-600 ml-2" :class="{ 'active-link': $route.name === 'home' }">
        <router-link to="/">Home</router-link>
      </li>
      <br>
      <li class="ml-2 pr-2 text-blue-500 hover:text-blue-600 ml-2" :class="{ 'active-link': $route.name === 'risk' }">
        <router-link to="/analisi-rischio">Analisi Rischio</router-link>
      </li>
      <br>
      <div v-if="isUserAuth">
        <li class="ml-2 pr-2 text-blue-500 hover:text-blue-600 ml-2" :class="{ 'active-link': $route.name === 'ott' }">
          <router-link to="/ottimizzazione">Ottimizzazione</router-link>
        </li>
        <br>
        <li class="ml-2 pr-2 text-blue-500 hover:text-blue-600 ml-2" :class="{ 'active-link': $route.name === 'sample' }">
        <router-link to="/sample">Campioni</router-link>
        </li>
        <br>
      <li class="ml-2 pr-2 text-blue-500 hover:text-blue-600 ml-2" :class="{ 'active-link': $route.name === 'exam' }">
        <router-link to="/exam">Esami</router-link>
      </li>
      <br>
      <router-link v-if="isUserAuth" :to="{ path: '/', query: { logout: 'true' } }"
        class="text-black hover:text-blue-600 ml-2">
        Logout
      </router-link>
      </div>
      <div v-else>
        <router-link to="/login" class="text-black hover:text-blue-600 font-bold ml-2">
          Accedi
        </router-link>

      </div>
    </ul>
   <!-- <br>
    <div class="msg_benvenuto_sotto flex-grow space-x-4 text-blue-600" v-if="isUserAuth">
      <p style="text-align:center">Benvenuto, <strong>{{ authStore.username }}</strong></p>
    </div>
  -->
  </div>
</template>






<script setup>
import { ref, watch } from 'vue';
import { useAuthStore } from '../stores/auth'; // Aggiungi il percorso corretto
const authStore = useAuthStore();
const isMobileMenuOpen = ref(false);
const isUserAuth = ref(false);

const showDropdown = ref(false);

isUserAuth.value = authStore.isAuthenticated;

const items = [
  { id: 1, route_name: 'ott', name: 'Ottimizzazione', to: 'ottimizzazione' },
  { id: 2, route_name: 'sample', name: 'Campioni', to: 'sample' },
  { id: 3, route_name: 'exam', name: 'Esami', to: 'exam' },
  { id: 4, route_name: '/', name: 'Logout', to: { path: '/', query: { logout: 'true' } }},
];

watch(
  () => authStore.access_token,
  (isAuth) => {
    isUserAuth.value = isAuth;
  }
);


const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
};

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

</script>

<style scoped>
@import '../assets/styles/header.css';

.cursor-pointer {
  cursor: pointer;
}

.circle-active {
  padding: 10px;
  background-color: white;
  border: 1px solid #4488d6;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
}

.circle-disable {
  padding: 10px;
  background-color: #4488d6;
  border: 1px solid blue;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
}

.icon {
  color: white;
  font-size: 24px;
}

.user-icon {
  position: relative;
  cursor: pointer;
}

.dropdown {
  display: block;
  position: absolute;
  top: 100%;
  right: 85%;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.dropdown div {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown div:hover {
  background-color: #f5f5f5;
}

.flex{
  justify-content: space-between;
}
</style>