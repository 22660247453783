import { defineStore } from 'pinia'
import { DATA_ENTRY_URL } from '../../../config.js';


//endpoint: /netpoints => lista di stringhe con i punti

//la GET ritorna un array, ogni elemento è fatto così:

//    {
//        "id": 0,
//        "pointID": "L74623224"
//    },



export const useNetpointsStore = defineStore({
    id: 'netpoints',
    state: () => ({
        netpoints: [],
        isLoading: false,
        error: null,

      }),
      getters: {
        countNetpoints(){
            return this.netpoints.length;
        },
      }, 
      actions: {
        //fixed è un intero (0 equivale a mobile, 1 a fisso)
        async getNetpointsList(fixed){
          this.loading = true;
          let queryParams = '';
          if(fixed != null){
            queryParams = 'fixed='+fixed;
          }
          try{
              const res = await fetch(DATA_ENTRY_URL + '/netpoints?'+queryParams);
      
              const data = await res.json();
              if(res['status'] != 200 || data['detail']){
                  throw("errore");
              }
              this.netpoints = data;
              }catch(e){
                  this.error = e;
                  throw(e);
              }finally{
                  
              this.loading = false;
              }
        },
        getNetpointByName(name){
          let netpoint;
          this.netpoints.find(item => {
            if(item.pointID === name){
              netpoint = {'id': item.id, 'pointID': item.pointID};
            }
          });
          return netpoint;
        },
        resetInstance(){
          this.netpoints=[];
          this.loading= false;
          this.error= null;
        }
      },
});