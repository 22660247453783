<template>
  <div class="chart-container">
    <div class="button-full-screen" @click="toggleFullScreen" :class="{ 'exit-fullscreen': isFullScreen }">
  <i class="fas fa-expand"></i> {{ isFullScreen ? 'Riduci schermo intero' : 'Visualizza schermo intero' }}
</div>
    <canvas ref="chartCanvas" min-height="400px"></canvas>
  </div>
  <div v-if="graficoAnalisiStore.nodesNotFound.length > 0">
    <div v-for="(node, index) in graficoAnalisiStore.nodesNotFound" :key="index" :value="node">
      <p>Il nodo {{ node }} non ha analisi</p>
    </div>
  </div>
</template>


<script setup>
import { ref, watch } from 'vue';
import Chart from 'chart.js/auto';
import { useGraficoAnalisiStore } from '../stores/grafico_analisi';
import { useNodeStore } from '../stores/node';
import { useCampagneStore } from '../stores/campagne';

const chartCanvas = ref(null);
const graficoAnalisiStore = useGraficoAnalisiStore();
const nodeStore = useNodeStore();
const campagneStore = useCampagneStore();
let currentChart = null;
const isFullScreen = ref(false);

//Cambia scritta se premi ESC e sei a schermo intero
document.addEventListener('fullscreenchange', () => {
  isFullScreen.value = !!document.fullscreenElement;
});

//Colori predefiniti delle stazioni
const chartColors = [
  'rgba(255, 0, 0, 0.6)',     // Rosso
  'rgba(0, 128, 255, 0.6)',   // Blu
  'rgba(153, 51, 255, 0.6)',  // Viola
  'rgba(51, 204, 51, 0.6)',   // Verde
  'rgba(255, 204, 0, 0.6)',   // Giallo acceso
  'rgba(255, 167, 159, 0.6)', // Rosso spento
  'rgba(243, 108, 255, 0.6)', // Rosa acceso
  'rgba(0, 255, 232, 0.6)',   // Celeste acceso
  'rgba(0, 0, 0, 0.6)',       // Nero
];

//Funzione per cambiare l'opacità del colore
const changeColorOpacity = (color, opacity) => {
  const parts = color.split(', ');
  parts[3] = opacity.toString();
  return parts.join(', ');
};

watch(
  () => graficoAnalisiStore.analisi,
  () => {
    if (graficoAnalisiStore.analisi.length < 1 || chartCanvas.value === null) return false;
    if (currentChart !== null) {
      currentChart.destroy();
    }
    
    const ctx = chartCanvas.value.getContext('2d');
    let exam_id = graficoAnalisiStore.analisi[0].examId;
    let options = null;

    let graficoType = null;

    if (exam_id === 4) {
      graficoType = 'bar';
      options = {
        scales: {
          y: {
            beginAtZero: false,
            min: -1,
            max: 1,
            stepSize: 1,
            title: {
              display: true,
              text: graficoAnalisiStore.analisi[0].units
            },
            ticks: {
              callback: (value) => {
                if (value === -1) return 'NO';
                if (value === 1) return 'SI';
                return '';
              },
              stepSize: 1,
              precision: 0,
              max: 1,
              min: 0
            }
          },
          x: {
            beginAtZero: true
          },
        },
        plugins: {
          title: {
            display: true,
            text: graficoAnalisiStore.analisi[0].examName
          },
          tooltip: {
            enabled: true,
            usePointStyle: true,
            titleAlign: 'center',
            titleColor: 'gold',
            titleSpacing: 3,
            TitleFont: {
              weight: 'bold'
            },
            backgroundColor: 'midnightblue',
            bodyColor: 'orange',
            bodyAlign: 'center',
            bodyFont: {
              weight: 'italic'
            },
            callbacks: {
              labelPointStyle: function () {
                return {
                  pointStyle: 'circle',
                  rotation: 0
                };
              },
              label: function (tooltipItem) {
                let nomeStazione = tooltipItem.dataset.label;
                if (tooltipItem.formattedValue === '1') return nomeStazione + ' : SI';
                return nomeStazione + ' : NO';
              }
            }
          }
        }
      };
    } else {
      graficoType = 'line';
      options = {
        scales: {
          y: {
            title: {
              display: true,
              text: graficoAnalisiStore.analisi[0].units
            },
          }
        }
      };
    }

    const chartData = {
      labels: campagneStore.getDates,
      datasets: graficoAnalisiStore.analisi.map(function (exam) {
        const node = nodeStore.getNodeById(exam.nodeID);
        const stationId = node == null ? exam.nodeID : node.label;
        
        const stazioneIndex = nodeStore.getStazioniArrayPosition(exam.nodeID);
        let stationColor = chartColors[0];
        if(stazioneIndex != null){
          stationColor = chartColors[stazioneIndex];
        }

        let values = null;
        if (exam_id === 4) {
          values = exam.values.map((value) => value === 0 ? -1 : 1);
        } else {
          values = exam.values;
        }

        return {
          label: stationId,
          data: values,
          borderWidth: 3,
          borderColor: changeColorOpacity(stationColor, 0.9),
          backgroundColor: stationColor,
          pointBackgroundColor: stationColor,
          pointRadius: 6,
          pointHoverRadius: 8,
        };
      }),
    };

    currentChart = new Chart(ctx, {
      type: graficoType,
      data: chartData,
      options: options,
    });
  },
);

const toggleFullScreen = () => {
  isFullScreen.value = !isFullScreen.value;
  if (isFullScreen.value) {
    enterFullScreen();
  } else {
    exitFullScreen();
  }
};

const enterFullScreen = () => {
  if (currentChart) {
    currentChart.options.rotation = 90;
    currentChart.update();
  }

  const chartContainer = chartCanvas.value.closest('.chart-container');
  if (chartContainer) {
    chartContainer.classList.add('full-screen');
  }

  if (chartContainer && chartContainer.requestFullscreen) {
    chartContainer.requestFullscreen();
  }
};

const exitFullScreen = () => {
  if (currentChart) {
    currentChart.options.rotation = 0;
    currentChart.update();
  }

  const chartContainer = chartCanvas.value.closest('.chart-container');
  if (chartContainer) {
    chartContainer.classList.remove('full-screen');
  }

  if (document.fullscreenElement) {
    document.exitFullscreen();
  }
};
</script>

<style scoped>
  @import '../assets/styles/chart.css';
</style>
