import { defineStore } from 'pinia'
import { API_BASE_URL } from '../../config.js';

//campaigns => array di oggetti:
//{
//    "id": 6,
//    "date": "2023-07-24",
//    "notes": null
//},
export const useCampagneStore = defineStore({
  id: 'campagne',
  state: () => ({
    campagne:[],
    camp_selezionata_id: null,
    loading: false,
    error: null
  }),
  getters: {
    countCampagne(){
        return this.campagne.length;
    },
    getDates(){
      return this.campagne.map(obj => obj.date);
    }
  },
  actions: {
    async getCampagneData(){
        this.loading = true;
        try{
        const res = await fetch(API_BASE_URL + '/api/campaigns');
        const data = await res.json();
        if(res['status'] != 200 || data['detail']){
          throw("errore");
        }
        this.campagne = data['campaigns'];
        this.campagne.reverse();
        }catch(e){

            this.error = "errore";
        }finally{
          this.loading = false;
        }
    }
  }
})