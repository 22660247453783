<!--
Pagina ottimizzazione => dettaglio ottimizazzione. siamo nel modal
Quando utente va on-hover su una route appare  questa legenda, con le info della route

-->

<template>
    <div class="legend-modal">
      <div class="legend-content">
        <div class="map-index">
            <div class="legend-item">
                <div class="legend-color" :style="{'background-color': optRouteStore.legendRoute['color']}"></div>
                <p>Route {{ optRouteStore.legendRoute['section_number'] + 1 }}</p>
          </div>
          <p><strong>Squadra </strong>{{ optRouteStore.legendRoute['team_index'] + 1}} </p>
          <p><strong>Distanza: </strong>{{ optRouteStore.legendRoute['distanceMeters']}} metri</p>
          <p><strong>Durata: </strong>{{ optRouteStore.legendRoute['durationSeconds']}} secondi</p>
          <p><strong>Nodo di partenza: </strong>{{ getRouteNode(true)}}</p>
          <p><strong>Nodo di Arrivo: </strong>{{ getRouteNode(false) }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { useOptRouteStore } from '../stores/optimization_route';
  import { useNodeStore } from '../stores/node';
  const optRouteStore = useOptRouteStore();
  const nodeStore = useNodeStore();


  const getRouteNode = (isStartingNode) =>{
    let nodoID = isStartingNode ? 'startNodeID' : 'endNodeID';
    let node = nodeStore.getNodeById(optRouteStore.legendRoute[nodoID])

    if(node['address'] !== ''){
        return node['address'];
    }

    return node['label'];
  };

  </script>
  
  
  <style>
  @import '../assets/styles/mapLegend.css';
  </style>