<template>
  <div class="flex-1 flex flex-col h-screen bg-blue-200">
    <div class="flex flex-grow p-4">
      <div class="flex-1 bg-white rounded-lg shadow-md p-6">
        <DateFilterComponent />
        <MapComponent class="w-full h-[70vh] mt-5" />
      </div>
    </div>
  </div>
</template>

<script setup>
import MapComponent from "../components/MapComponent.vue";
import DateFilterComponent from "../components/DateFilterComponent.vue";

import { useNodeStore } from "../stores/node";
import { useEdgeStore } from "../stores/edge";
import { useCampagneStore } from "../stores/campagne";

const nodeStore = useNodeStore();
const edgeStore = useEdgeStore()
const campagneStore = useCampagneStore();

//Check sui valori
if(nodeStore.nodes.length < 1 || edgeStore.edges.length < 1 || campagneStore.campagne.length < 1){

  nodeStore.initNodes();
  edgeStore.initializeEdges()
  campagneStore.getCampagneData();
}

</script>