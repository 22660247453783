<template>
  <div class="flex flex-col xl:flex-row h-screen bg-blue-200">
    <div class="flex-1 flex flex-col">
      <div class="flex flex-1">
        <div class="w-full m-5 bg-white rounded-lg shadow-md p-6">

          <div class="w-full mb-4 xl:w-2/3 xl:mb-0">
            <DateFilterComponent />
          </div>

          <div class="flex flex-col xl:flex-row">
            <div class="w-full xl:w-1/3 p-4">
              <OptimizationFormComponent />
            </div>

            <div class="w-full xl:w-2/3 p-4">
              <div class="h-[400px] xl:h-auto overflow-y-auto">
                <OptimizationHistoryComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>





<script setup>
import OptimizationFormComponent from "../components/OptimizationFormComponent.vue";
import OptimizationHistoryComponent from "../components/OptimizationHistoryComponent.vue";
import DateFilterComponent from "../components/DateFilterComponent.vue";

import { useNodeStore } from "../stores/node";
import { useEdgeStore } from "../stores/edge";
import { useCampagneStore } from '../stores/campagne';

const nodeStore = useNodeStore();
const edgeStore = useEdgeStore();
const campagneStore = useCampagneStore();

if (nodeStore.nodes.length < 1 || edgeStore.edges.length < 1 || campagneStore.campagne.length < 1) {

  campagneStore.getCampagneData(); 

  nodeStore.initNodes();
  edgeStore.initializeEdges();
}
</script>