<template>
    <div class="flex-1 flex flex-col">
      <HeaderComponent />
    </div>

  <router-view/>
</template>

<script setup>
import HeaderComponent from "./components/HeaderComponent.vue";
import { useAuthStore } from "./stores/auth";

const authStore = useAuthStore();

authStore.getCookieContent();
</script>