import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from "primevue/config";
import Dropdown from 'primevue/dropdown';
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import { createPinia } from 'pinia'
import './assets/tailwind.css'

 
const pinia = createPinia()

createApp(App).use(router).use(pinia).use(PrimeVue).component("Dropdown", Dropdown).mount('#app')