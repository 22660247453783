import { defineStore } from 'pinia'
import edgesData from '../data/edges.json'
export const useEdgeStore = defineStore({
  id: 'edge',
  state: () => ({
    edges:[],
    edge: null,
    loading: false,
    error: null
  }),
  getters: {
  }, 
  actions: {
    //inizializzo gli Archi dal json interno
    initializeEdges() {
        this.edges = edgesData.data
      },
  }
})