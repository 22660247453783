<template>
  <div class="relative h-5/6 w-full self-end border-2 border-gray-600 rounded-lg overflow-hidden shadow-md">
    <div id="map" class="h-full"
      :style="{ opacity: loaderStore.isLoading ? '0.4' : '1', 'pointer-events': loaderStore.isLoading ? 'none' : 'auto' }">
    </div>
    <MapLegendComponent v-if="router.currentRoute.value.name !== 'ott'" />
    <MapLegendRouteComponent v-if="optRouteStore.legendRoute !== null" />


    <!--Modal piccolo all'onhover del mouse in alto a sinistra sulla mappa-->
    <div v-if="selectedNode" class="modal" :class="{ active: selectedNode }" :style="{
      top: selectedNodePosition.y + 'px',
      left: selectedNodePosition.x + 'px'
    }">
      <div class="modal-content" v-if="router.currentRoute.value.name !== 'ott'">
        <p class="modal-title">Dettaglio Nodo</p>
        <p><strong>Tipo:</strong> {{ selectedNode.type }}</p>
        <p><strong>Indirizzo:</strong> {{ selectedNode.address }}</p>
        <p><strong>Popolazione:</strong> {{ selectedNode.population }}</p>

        <p class="mt-5" v-if="selectedNode.risk_color"><strong>Indice di rischio: </strong>{{ selectedNode.risk_index }}
        </p>
        <div v-if="!('exams' in selectedNode)">
        </div>
      </div>
      <div class="modal-content" v-else>
        <p class="modal-title">Dettaglio Nodo</p>
          <p><strong>Indirizzo:</strong> {{ selectedNode.address }}</p>
          <p><strong>Popolazione:</strong> {{ selectedNode.population }}</p>
      </div>
    </div>

    <!-- Modal più grande all'onclick click sul nodo -->
    <div v-if="selectedNodeDetails" id="detail_large_modal" class="modal large-modal"
      :class="{ active: selectedNodeDetails }">
      <div class="modal-content large-modal-content">
        <span class="close-icon" @click="closeLargeModal">×</span>

        <div v-if="'exams' in selectedNodeDetails">
          <div class="flex items-center space-x-2">
            <label class="block font-bold mb-1" for="date-range">Analisi</label>
            <span class="exam-date"><i>({{ selectedNodeDetails.date }})</i>:</span>
          </div>

          <div class="mt-2" v-for="(analisi, index) in selectedNodeDetails.exams" :key="index">

            <p>
              <strong>{{ analisi.examName }}</strong>:
              {{
                analisi.examName === 'Presenza COVID' ?
                (analisi.examValue === 1 ? 'Si' : 'No') :
                (analisi.examValue % 1 === 0 ? analisi.examValue : analisi.examValue.toFixed(6))
              }}
              <i>{{ analisi.valueUnit }}</i>
            </p>
            <hr>
          </div>
        </div>

        <div v-else>
          <p><strong>Tipo:</strong> {{ selectedNodeDetails.type }}</p>
          <p><strong>Indirizzo:</strong> {{ selectedNodeDetails.address }}</p>
          <p><strong>Popolazione:</strong> {{ selectedNodeDetails.population }}</p>
          <p class="mt-5" v-if="selectedNodeDetails.risk_color"><strong>Indice di rischio: </strong>{{
            selectedNodeDetails.risk_index }}</p>
        </div>
      </div>
    </div>



  </div>
</template>

<script setup>
import leaflet from "leaflet";
import "leaflet-polylinedecorator";
import MapLegendComponent from './MapLegendComponent.vue';
import MapLegendRouteComponent from './MapLegendRouteComponent.vue';
import { onMounted, ref, reactive, watch } from "vue";
import { useNodeStore } from "../stores/node";
import { useEdgeStore } from "../stores/edge";
import { useLoaderStore } from "../stores/loader";
import { useOptRouteStore } from '../stores/optimization_route';
import { useRouter } from 'vue-router';

//References
const nodeStore = useNodeStore();
const edgeStore = useEdgeStore();
const loaderStore = useLoaderStore();
const optRouteStore = useOptRouteStore();
const router = useRouter();

const analisiSelezionata = ref(null);
const selectedNode = ref(null);
const selectedNodePosition = reactive({ x: 0, y: 0 });
const selectedNodeDetails = ref(null);


//Init 
let clicked_selected_node_lat = 0;
let clicked_selected_node_lon = 0;
let polylinesLayer = null;
let nodesLayer = null;
let analyzedNodesLayer = null;
let map = null;
let selectedNodeObj = null;
let selectedNodeAnalyzedObj = null;
let selectedNodeRiskObj = null;
let routeNodesLayer = null;

//Stili di default
const analizedNodeStyle = {
  radius: 6,
  color: "#ffff00",
  fillColor: "#ff001e",
  fillOpacity: 0.9
};

const selectedAnalyzedNodeStyle = {
  radius: 12,
  color: "#ffff00",
  fillColor: "#ff001e",
  fillOpacity: 0.9
};


const normalNodeStyle = {
  radius: 0.6,
  color: "#244F6C",
  fillColor: "#244F6C",
  fillOpacity: 0.6,
  zIndexOffset: 1,
};

const selectedNodeStyle = {
  radius: 12,
  color: "#0044ff",
  fillColor: "#0099ff",
  fillOpacity: 1,
  zIndexOffset: 2,
};

const riskNodeStyle = {
  radius: 0,
  color: "",
  fillColor: "",
  fillOpacity: 1,
  zIndexOffset: 0,
};




onMounted(() => {
  map = initMap();
  if (router.currentRoute.value.name !== 'ott') {
    drawLines(map, edgeStore.edges);
    drawNodes(map, nodeStore.nodes);
  }

  //Evento che parte ogni volta che zoomo
  map.on("zoomend", handleZoomEnd);
});

watch(() => nodeStore.nodes && loaderStore.isLoading, () => {
  if (loaderStore.isLoading) {
    return;
  }
  //pulisco tutti i nodi disegnati creati
  nodesLayer.clearLayers();
  analyzedNodesLayer.clearLayers();

  //cancello i 2 layer
  nodesLayer.remove();
  analyzedNodesLayer.remove();
  drawNodes(map, nodeStore.nodes);
});

//ogni volta che clicco su: dettaglio ottimizzazione => apro mappa e carico le Route
watch(
  () => optRouteStore.getSquadraRoutes,
  (routes) => {
    if (optRouteStore.selected_route_info !== null) {
      //pulisco i layer
      if(routeNodesLayer){
        routeNodesLayer.clearLayers();
        routeNodesLayer.remove();
      }
      if(polylinesLayer){
        polylinesLayer.clearLayers();
        polylinesLayer.remove();
      }
      drawRoute(map, routes);
    }
  });

//Aimazione Stazione selezionata
watch(
  () => nodeStore.stazione_corrente,
  () => {
    if (nodeStore.stazione_corrente !== null) {
      var lastNodeId = nodeStore.stazione_corrente[nodeStore.stazione_corrente.length - 1];

      let nodeToAnimate = null;

      //Se il filtro data è selezionato allora vado ad animare un nodo tra quelli con analisi. Altrimenti vado a cercare tutti i nodi.
      if (analyzedNodesLayer.getLayers().length > 0) {
        nodeToAnimate = analyzedNodesLayer.getLayers().find((marker) => {
          return marker.options.node_id === lastNodeId;
        });
      } else {
        nodeToAnimate = nodesLayer.getLayers().find((marker) => {
          return marker.options.node_id === lastNodeId;
        });
      }

      if (nodeToAnimate) {
        nodeToAnimate.bringToFront();
        nodeToAnimate.getElement().classList.add("animate-marker");

        map.setView([nodeToAnimate.getLatLng().lat, nodeToAnimate.getLatLng().lng]);


        setTimeout(() => {
          nodeToAnimate.getElement().classList.remove("animate-marker");
        }, 1500);
      }
    }
  }
);




function handleZoomEnd() {

  const currentZoom = map.getZoom();

  if (router.currentRoute.value.name !== 'ott') {
    if (currentZoom > 16.5) {
      polylinesLayer.remove();
      polylinesLayer.addTo(map);

    } else {
      polylinesLayer.remove();
      deselectNode();
    }
  }


  resetLayersOrder();
}

//Funzione che de
function deselectNode() {

  if (selectedNodeObj) {
    selectedNodeObj.setStyle(normalNodeStyle);

    selectedNodeObj = null;
  }

  if (selectedNodeAnalyzedObj) {
    selectedNodeAnalyzedObj.setStyle(analizedNodeStyle);

    selectedNodeAnalyzedObj = null;
  }


  if (selectedNodeRiskObj) {
    selectedNodeRiskObj.setStyle({
      radius: selectedNodeRiskObj.prevRadius,
    });

  }

  selectedNodeDetails.value = null;
  selectedNode.value = null; //deseleziona il nodo

  clicked_selected_node_lon = 0;
  clicked_selected_node_lat = 0;
}


function initMap() {

  //Init Verbania Position
  const verbania_lat_north = 45.98; //estensione a nord
  const verbania_lat_south = 45.9; //estensione a sud
  const verbania_lon_ovest = 8.5; //estensione ad ovest
  const verbania_lon_est = 8.65; //estensione ad est
  const southWest = leaflet.latLng(verbania_lat_south, verbania_lon_ovest);
  const northEast = leaflet.latLng(verbania_lat_north, verbania_lon_est);


  const bounds = leaflet.latLngBounds(southWest, northEast);

  //utile per disegnare i bounds
  //const extensionLine = leaflet.polyline([[verbania_lat_south, verbania_lon_ovest], [verbania_lat_south, verbania_lon_est], [verbania_lat_north, verbania_lon_est], [verbania_lat_north,verbania_lon_ovest]], {
  //  color: 'red',  // Colore della linea (puoi personalizzarlo)
  //  weight: 2,      // Spessore della linea (puoi personalizzarlo)
  //  opacity: 0.7    // Opacità della linea (puoi personalizzarlo)
  //});
  let init_lat;
  let init_lon;
  let init_zoom;



  if (router.currentRoute.value.name === 'ott') {
    init_lat = 45.930284;
    init_lon = 8.555142;
    init_zoom = 25;
  } else if (router.currentRoute.value.name === 'risk') {
    init_lat = 45.933950;
    init_lon = 8.554755;
    init_zoom = 13.2;
  } else { //home
    init_lat = 45.928695;
    init_lon = 8.555281;
    init_zoom = 12.5;
  }

  let map = leaflet.map("map").setView([init_lat, init_lon], init_zoom);
  leaflet
    .tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 20,
      //attribution: "© OpenStreetMap",
    })
    .addTo(map);


  map.setMaxBounds(bounds);
  map.fitBounds(bounds);
  map.setMinZoom(13);
  map.setMaxZoom(20);

  //extensionLine.addTo(map);
  return map;
}


function getRadiusByRisk(val) {

  let ret = 5;

  if (val > ret) {

    return ret;

  } else if (val > 0.1) {
    return 3;
  }

  return val;
}

function getModalOffsetOnClick() {

  let ret = 0;

  //Questa serie di if è volta a mostrare il modal con il dettaglio e il nodo a fianco con la miglior accuratezza possibile in base alla risoluzione.
  if (router.currentRoute.value.name === 'home') {

    if (window.innerWidth >= 450 && window.innerWidth < 768) {

      ret = 0.0022;

    } else if (window.innerWidth < 450) {

      ret = 0.0016;

      if (window.innerWidth < 400) {
        ret = 0.0013;
      }

    } else if (window.innerWidth >= 768) {

      ret = 0.0024;
    }
  }

  return ret;

}


function clickOnNode(map, nodeMarker, nodeData) {


  let offsetLon = getModalOffsetOnClick();
  const zoomLevel = 17;
  const newLon = nodeData.lon + offsetLon;


  map.setView([nodeData.lat, newLon], zoomLevel);

  //Resetta gli stili dei nodi selezionati precedentemente
  if (selectedNodeObj) {
    selectedNodeObj.setStyle(normalNodeStyle);
  }

  if (selectedNodeAnalyzedObj) {
    selectedNodeAnalyzedObj.setStyle(analizedNodeStyle);
  }


  if (nodeData['analyzed']) {  //Pagina Home. Nodo analizzato (rosso).

    nodeMarker.setStyle(selectedAnalyzedNodeStyle);
    selectedNodeAnalyzedObj = nodeMarker;

  } else if (nodeData['analyzed_risk']) { //Pagina Risk

    //Quando clicco su un altro nodo, lo style di quello precedente deve tornare lo stesso.
    if (selectedNodeRiskObj) {
      selectedNodeRiskObj.setStyle({
        radius: selectedNodeRiskObj.prevRadius,
      });
    }

    selectedNodeRiskObj = nodeMarker;
    selectedNodeRiskObj.prevRadius = getRadiusByRisk(nodeData["risk_index"]);
    selectedNodeRiskObj.prevOpacity = getRadiusByRisk(nodeData["risk_index"]);

    nodeMarker.setStyle({
      radius: 10,
      color: nodeData['risk_color'],
      fillOpacity: 1,
    });

  } else { //Pagina Home. Nodo normale (blu).
    nodeMarker.setStyle(selectedNodeStyle);
    selectedNodeObj = nodeMarker;
  }


  //Porta il marker del nodo in primo piano.
  nodeMarker.bringToFront();


  //Imposta i dettagli del nodo selezionato per il modal
  selectedNodeDetails.value = nodeData;
  selectedNode.value = null;
  clicked_selected_node_lat = nodeData.lat;
  clicked_selected_node_lon = newLon;
  analisiSelezionata.value = null;


  /*Cambio la larghezza del modal di dettaglio in base alla schermata in quale sono. 
  Il timeout serve perché non caricava il DOM in tempo quindi poteva non rilevare il camio di pagina.*/
  let modalWidth = 0;

  if (router.currentRoute.value.name === 'risk') {
    modalWidth = '35%';
  } else if (router.currentRoute.value.name === 'home') {
    modalWidth = '65%';
  }

  setTimeout(() => {
    const modal = document.querySelector('#detail_large_modal');
    if (modal) {
      modal.style.width = modalWidth;
    }
  }, 0);
}



function getNodeStyle(nodeData) {

  let ret;

  if (nodeData['analyzed']) {
    ret = analizedNodeStyle;

  } else if (nodeData['analyzed_risk']) {

    riskNodeStyle["fillColor"] = nodeData['risk_color'];
    riskNodeStyle["color"] = nodeData['risk_color'];
    riskNodeStyle["radius"] = getRadiusByRisk(nodeData['risk_index']);
    ret = riskNodeStyle;
  } else {
    ret = normalNodeStyle;
  }

  return ret;

}



function drawNodes(map, nodes) {

  nodesLayer = leaflet.layerGroup().addTo(map);
  analyzedNodesLayer = leaflet.layerGroup().addTo(map);


  nodes.forEach((nodeData) => {

    let style = getNodeStyle(nodeData);

    let nodeMarker = leaflet.circleMarker([nodeData.lat, nodeData.lon], {
      ...style,
      node_id: nodeData.node_id,
    });

    nodeMarker.on("mouseover", () => {
      selectedNode.value = nodeData;
    });

    nodeMarker.on("mouseout", () => {
      selectedNode.value = null;
    });

    nodeMarker.on("click", () => {
      clickOnNode(map, nodeMarker, nodeData);
    });


    //Aggiungi il marker del nodo al layer appropriato (nodi normali o nodi analizzati)
    if (nodeData['analyzed']) {
      nodeMarker.addTo(analyzedNodesLayer);
    } else {
      nodeMarker.addTo(nodesLayer);
    }
  });

  resetLayersOrder();
}




const routeColors = [
`rgb(250,5,5)`,
`rgb(250, 5, 238)`,
`rgb(5, 21, 250)`,
`rgb(5, 250, 91)`,
`rgb(5, 250, 221)`,
`rgb(5, 177, 250)`,
`rgb(250, 152, 5)`,
`rgb(242, 250, 5)`,
`rgb(172, 5, 250)`,
`rgb(250, 5, 213)`,
];

const routeHorizontalOffset = 0.00003; //Distanza tra le polylines

function drawRoute(map, routes) {
  polylinesLayer = leaflet.layerGroup().addTo(map);
  routeNodesLayer = leaflet.layerGroup().addTo(map);

  routes.forEach((routeData, index) => {
    const routeColor = routeColors[index % routeColors.length];

    // Disegna le frecce
    drawRouteLines(routeData, routeColor, polylinesLayer, index * routeHorizontalOffset);
    
    // Disegna poi i nodi principali:
    let starting_node = nodeStore.getNodeById(routeData['startNodeID']);
    let nodeMarker = leaflet.circleMarker([starting_node.lat, starting_node.lon], {
      ...analizedNodeStyle,
      node_id: routeData['startNodeID'],
    });
    nodeMarker.on("mouseover", () => {
      selectedNode.value = starting_node;
    });
    nodeMarker.on("mouseout", () => {
      selectedNode.value = null;
    });

    nodeMarker.addTo(routeNodesLayer);

    routeData['color'] = routeColor;
  });
}

function drawRouteLines(routeData, routeColor, polylinesLayer, offset) {
  let polylines = routeData['decodedPolyLine'];

  for (let i = 0; i < polylines.length - 1; i++) {
    let curr_coordinate = polylines[i];

    let succ_coordinate = polylines[i + 1];
    let coord_inizio = [curr_coordinate['0'] + offset, curr_coordinate['1']];
    let coord_fine = [succ_coordinate['0'] + offset, succ_coordinate['1']];

    const linea = leaflet.polyline([coord_inizio, coord_fine], {
      color: routeColor,
    }).addTo(polylinesLayer);

    const arrowHead = leaflet.polylineDecorator(linea, {
      patterns: [
        {
          offset: "90%",
          repeat: 0,
          symbol: leaflet.Symbol.arrowHead({
            pixelSize: 6,
            polygon: true,
            pathOptions: { stroke: true, color: routeColor },
          }),
        },
      ],
    });

    arrowHead.addTo(polylinesLayer);
  }

  resetLayersOrder();
}

//Resetto in modo che i nodi analizzati siano sempre in primo piano
function resetLayersOrder() {
  if (nodesLayer) {
    nodesLayer.remove();
    nodesLayer.addTo(map);
  }

  if (analyzedNodesLayer) {
    analyzedNodesLayer.remove();
    analyzedNodesLayer.addTo(map);
  }

}



//Disegna la direzione dell'acqua
function drawLines(map, edges) {
  polylinesLayer = leaflet.layerGroup().addTo(map);

  edges.forEach((edge) => {
    let coord_inizio = [edge.start_node.lat, edge.start_node.lon];
    let coord_fine = [edge.end_node.lat, edge.end_node.lon];

    const polyline = leaflet.polyline([coord_inizio, coord_fine], {
      color: "rgb(120,156,178)",
    }).addTo(polylinesLayer);

    const arrowHead = leaflet.polylineDecorator(polyline, {
      patterns: [
        {
          offset: "90%",
          repeat: 0,
          symbol: leaflet.Symbol.arrowHead({
            pixelSize: 5,
            polygon: true,
            pathOptions: { stroke: true, color: "rgb(120,156,178)" },
          }),
        },
      ],
    });
    arrowHead.addTo(polylinesLayer);
  });

  polylinesLayer.remove();
}


function closeLargeModal() {

  map.setView([clicked_selected_node_lat, clicked_selected_node_lon], 13.5); //riporta la mappa allo zoom e alla posizione iniziale
  deselectNode();
}

</script>



<style>
@import '../assets/styles/map.css';
</style>